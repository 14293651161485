<template>
  <div>
    <h2 class="mt-0" style="color: var(--text-color)">Content Packs</h2>
    <div
      class="p-3 pb-0 flex flex-column sm:flex-row justify-content-between gap-3"
    >
      <Button class="justify-content-center" @click="navigateToAddContentPack"
        >Add New Content Pack</Button
      >
    </div>
    <ContentPacksList />
  </div>
</template>

<script>
import ContentPacksList from "../components/ContentPacksList.vue";
import { useRouter } from "vue-router";
import Button from "primevue/button";

export default {
  components: {
    ContentPacksList,
    Button,
  },
  setup() {
    const router = useRouter();

    const navigateToAddContentPack = () => {
      router.push({ name: "AddContentPack" });
    };

    return {
      navigateToAddContentPack,
    };
  },
};
</script>

<style scoped>
/* Add your styles here */
</style>
