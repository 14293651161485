<template>
  <div>
    <h2 class="text-xl mb-3" style="color: var(--text-color)">
      Shows Management
    </h2>
    <Card>
      <template #header>
        <div
          class="flex flex-wrap justify-content-between align-items-center p-2"
        >
          <div>
            <Button class="p-button-sm" @click="goToCreateNewShow()"
              >Add New Show</Button
            >
          </div>
          <div class="flex flex-wrap gap-2">
            <Dropdown
              v-model="podcasterId"
              :options="podcasters"
              optionValue="podcaster_id"
              optionLabel="email"
              class="w-full sm:w-auto"
              placeholder="Select Podcaster"
            />
            <InputText
              class="w-full sm:w-auto"
              v-model="showsTableFilter.global.value"
              type="text"
              placeholder="Global Search"
            />
          </div>
        </div>
      </template>
      <template #content>
        <DataTable
          :value="shows"
          :paginator="true"
          :rows="10"
          :filters="showsTableFilter"
          class="p-datatable-sm"
          :striped-rows="true"
          :globalFilterFields="[
            'show_title',
            'email',
            'categories',
            'show_description',
          ]"
        >
          <Column
            field="show_id"
            header="ID"
            :sortable="true"
            style="width: 5%"
          >
            <template #body="slotProps">
              <div class="truncate-text" :title="slotProps.data.show_id">
                {{ slotProps.data.show_id }}
              </div>
            </template>
          </Column>
          <Column
            field="show_title"
            header="Title"
            :sortable="true"
            style="width: 25%"
          >
            <template #body="slotProps">
              <div class="truncate-text" :title="slotProps.data.show_title">
                {{ slotProps.data.show_title }}
              </div>
            </template>
          </Column>
          <Column
            field="email"
            header="Podcaster"
            :sortable="true"
            style="width: 20%"
          >
            <template #body="slotProps">
              <div class="truncate-text" :title="slotProps.data.email">
                {{ slotProps.data.email }}
              </div>
            </template>
          </Column>
          <Column
            field="hosted"
            header="Hosted"
            :sortable="true"
            style="width: 20%"
          >
            <template #body="slotProps">
              <div class="truncate-text" :title="slotProps.data.hosted">
                {{ slotProps.data.hosted }}
              </div>
            </template>
          </Column>
          <Column
            field="categories"
            header="Categories"
            :sortable="true"
            style="width: 20%"
          >
            <template #body="slotProps">
              <div class="truncate-text" :title="slotProps.data.categories">
                {{ slotProps.data.categories }}
              </div>
            </template></Column
          >
          <Column
            field="avg_per_month"
            header="Avg Monthly Downloads"
            :sortable="true"
            style="width: 15%"
          ></Column>
          <Column header="Actions" style="width: 15%">
            <template #body="slotProps">
              <div class="flex gap-1">
                <Button
                  icon="pi pi-eye"
                  class="p-button-sm p-button-outlined"
                  @click="showDetails(slotProps.data)"
                />
                <Button
                  icon="pi pi-pencil"
                  class="p-button-sm p-button-outlined"
                  @click="
                    goToUpdateShow(
                      slotProps.data.show_id,
                      slotProps.data.fetch_status
                    )
                  "
                />
                <Button
                  icon="pi pi-trash"
                  class="p-button-sm p-button-outlined p-button-danger"
                  @click="confirmDeleteShow(slotProps.data.show_id)"
                />
                <Button
                  icon="pi pi-refresh"
                  class="p-button-sm p-button-outlined"
                  :loading="fetchLoading === slotProps.data.show_id"
                  @click="triggerFetchShow(slotProps.data.show_id)"
                />
              </div>
            </template>
          </Column>
          <template #paginatorstart>
            <Button
              type="button"
              icon="pi pi-refresh"
              @click="refreshShows()"
              text
            />
          </template>
          <template #paginatorend>
            <download-csv :data="shows" class="download-csv">
              <Button type="button" icon="pi pi-download" text />
            </download-csv>
          </template>
        </DataTable>
      </template>
    </Card>

    <Dialog
      v-model:visible="detailsVisible"
      header="Show Details"
      :style="{ width: '50vw' }"
      :modal="true"
    >
      <div v-if="selectedShow" class="grid">
        <div class="col-12 md:col-6">
          <strong>Show ID:</strong> {{ selectedShow.show_id }}
        </div>
        <div class="col-12 md:col-6">
          <strong>Title:</strong> {{ selectedShow.show_title }}
        </div>
        <div class="col-12">
          <strong>Description:</strong> {{ selectedShow.show_description }}
        </div>
        <div class="col-12 md:col-6">
          <strong>RSS URL:</strong> {{ selectedShow.rss_url }}
        </div>
        <div class="col-12 md:col-6">
          <strong>Categories:</strong> {{ selectedShow.categories }}
        </div>
        <div class="col-12 md:col-6">
          <strong>Podcaster ID:</strong> {{ selectedShow.podcaster_id }}
        </div>
        <div class="col-12 md:col-6">
          <strong>Podcaster Email:</strong> {{ selectedShow.email }}
        </div>
      </div>
    </Dialog>
  </div>
</template>

<script setup>
import Card from "primevue/card";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Dropdown from "primevue/dropdown";
import Dialog from "primevue/dialog";
import { ref, computed, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { FilterMatchMode } from "primevue/api";
import axios from "axios";
import { API_BASE_URL } from "@/consts";
import { useToast } from "primevue/usetoast";
import ConfirmDialog from "primevue/confirmdialog";
import { useConfirm } from "primevue/useconfirm";

const confirm = useConfirm();
const store = useStore();
const fetchLoading = ref("");
const router = useRouter();
const toast = useToast();
const showsTableFilter = ref({
  global: { value: null, matchMode: FilterMatchMode.CONTAINS },
});
const token = localStorage.getItem("podcastAdminToken");
const podcasterId = ref("podcastAdmin");
const detailsVisible = ref(false);
const selectedShow = ref(null);

const goToCreateNewShow = () => {
  router.push({ name: "AddPodcastAdminShow" });
};

const goToUpdateShow = (showId, fetchStatus) => {
  // if (fetchStatus !== "fetched") {
  //   toast.add({
  //     severity: "warn",
  //     summary: "Edit not allowed",
  //     detail: "Show data is not fetched yet, please try again later.",
  //     life: 4000,
  //   });
  //   return;
  // }
  router.push({
    name: "UpdatePodcastAdminShow",
    params: { showId },
  });
};

const triggerFetchShow = async (showId) => {
  try {
    fetchLoading.value = showId;
    const response = await axios.post(
      API_BASE_URL + "/podcaster/shows/" + showId + "/fetch",
      undefined,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.data?.success) {
      toast.add({
        severity: "success",
        summary: "Success",
        detail: `Fetch started for the show`,
        life: 4000,
      });
    }
  } catch (error) {
    console.error("Error fetching shows:", error);
    toast.add({
      severity: "error",
      summary: "Error",
      detail: `Failed to start fetch for the show`,
      life: 4000,
    });
  } finally {
    fetchLoading.value = "";
  }
};

const goToCampaigns = (showId) => {
  router.push({
    name: "PodcastAdminCampaignsList",
    query: { showId },
  });
};

const showDetails = (show) => {
  selectedShow.value = show;
  detailsVisible.value = true;
};

const shows = computed(() => {
  let tempShowList = store.getters.shows;
  if (!tempShowList) {
    tempShowList = [];
  }
  if (podcasterId.value == "podcastAdmin") {
    return tempShowList;
  } else {
    return tempShowList.filter(
      (item) => item.podcaster_id == podcasterId.value
    );
  }
});

const deleteShow = async (showId) => {
  try {
    const response = await axios.delete(
      `${API_BASE_URL}/podcaster/shows/${showId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.data?.success) {
      toast.add({
        severity: "success",
        summary: "Success",
        detail: "Show deleted successfully",
        life: 4000,
      });
      refreshShows();
    }
  } catch (error) {
    console.error("Error deleting show:", error);
    toast.add({
      severity: "error",
      summary: "Error",
      detail: "Failed to delete the show",
      life: 4000,
    });
  }
};

const confirmDeleteShow = (showId) => {
  confirm.require({
    message: "Are you sure you want to delete this show?",
    header: "Confirmation",
    icon: "pi pi-exclamation-triangle",
    accept: () => {
      deleteShow(showId);
    },
    reject: () => {
      // optional: handle rejection
    },
  });
};

const podcasters = computed(() => {
  let tempPodcasters = store.getters.podcasters;
  if (!tempPodcasters) {
    tempPodcasters = [];
  }
  tempPodcasters = tempPodcasters.map((item) => ({ ...item }));
  tempPodcasters.unshift({
    email: "All Podcasters",
    podcaster_id: "podcastAdmin",
  });
  return tempPodcasters;
});

const refreshShows = () => {
  store.dispatch("loadShows", { token });
};

const fetchShows = () => {
  if (!store.getters.shows || store.getters.shows.length == 0) {
    store.dispatch("loadShows", { token });
  }
};

const fetchPodcasters = async () => {
  if (!store.getters.podcasters || store.getters.podcasters.length == 0) {
    store.dispatch("loadPodcasters", { token });
  }
};

onMounted(() => {
  fetchShows();
  fetchPodcasters();
  if (router.currentRoute.value.query.podcasterId) {
    podcasterId.value = router.currentRoute.value.query.podcasterId;
  }
});
</script>

<style scoped>
.p-datatable .p-datatable-thead > tr > th,
.p-datatable .p-datatable-tbody > tr > td {
  padding: 0.5rem;
  font-size: 0.9rem;
}

.p-card .p-card-body {
  padding: 0.5rem;
}

.p-dropdown,
.p-inputtext,
.p-button.p-button-sm {
  font-size: 0.9rem;
}

.p-button.p-button-sm {
  padding: 0.3rem 0.5rem;
}

.truncate-text {
  max-width: 200px; /* Adjust this value to set the desired maximum width */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block; /* Ensures the div takes the full width of its container */
}

.p-datatable .p-datatable-tbody > tr > td {
  padding: 0.5rem;
  font-size: 0.9rem;
  max-height: 3.5em; /* Adjust this value to control the maximum height of the cells */
  overflow: hidden;
}

/* For wrapped text in other columns */
.wrap-text {
  max-width: 200px; /* Adjust this value as needed */
  white-space: normal;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Number of lines to show */
  -webkit-box-orient: vertical;
}
</style>
