<template>
  <div :class="{ 'blurred-background': editDialogVisible }">
    <h2 class="mt-0" style="color: var(--text-color)">{{ title }}</h2>

    <Card>
      <template #header>
        <div v-if="pendingSellers.length > 0" class="p-3 pb-0">
          <div class="custom-expandable-section w-full">
            <Button
              @click="togglePendingSellers"
              :icon="
                isPendingSellersExpanded
                  ? 'pi pi-chevron-up'
                  : 'pi pi-chevron-down'
              "
              :label="`${pendingSellers.length} new sellers to approve`"
              class="p-button-text w-full justify-content-between"
              :class="{ 'p-button-warning': isPendingSellersExpanded }"
            />
            <div v-if="isPendingSellersExpanded" class="expandable-content">
              <ul v-if="pendingSellers.length > 0" class="list-none p-0 m-0">
                <li
                  v-for="seller in pendingSellers"
                  :key="seller.id"
                  class="mb-2 p-2 border-bottom-1 surface-border"
                >
                  <strong>ID:</strong> {{ seller.id }} | <strong>Name:</strong>
                  {{ seller.name }} |
                  <strong>Domain:</strong>
                  <a
                    :href="formatDomainURL(seller.domain)"
                    target="_blank"
                    class="no-style-link"
                  >
                    {{ seller.domain }}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div v-if="recentlyVerifiedSellers.length > 0" class="p-3 pb-0">
          <div class="custom-expandable-section w-full">
            <Button
              @click="toggleRecentVerified"
              :icon="
                isRecentVerifiedExpanded
                  ? 'pi pi-chevron-up'
                  : 'pi pi-chevron-down'
              "
              :label="`${recentlyVerifiedSellers.length} sellers' ads.txt verified in the last 7 days`"
              class="p-button-text w-full justify-content-between"
              :class="{ 'p-button-success': isRecentVerifiedExpanded }"
            />
            <div v-if="isRecentVerifiedExpanded" class="expandable-content">
              <div v-if="recentlyVerifiedSellers.length === 0">
                No recently verified sellers found.
              </div>
              <ul v-else class="list-none p-0 m-0">
                <li
                  v-for="seller in recentlyVerifiedSellersArray"
                  :key="seller.id"
                  class="mb-2"
                >
                  <strong>ID:</strong> {{ seller.id }} |
                  <strong>Domain:</strong>
                  <a
                    :href="formatDomainURL(seller.domain)"
                    target="_blank"
                    class="no-style-link"
                  >
                    {{ seller.domain }}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div
          class="p-3 pb-0 flex flex-column sm:flex-row justify-content-between gap-3"
        >
          <Button
            size="small"
            class="justify-content-center"
            label="Add New Seller"
            icon="pi pi-plus"
            @click="openSelectPartner"
          />
          <InputText
            class="w-full sm:w-24rem"
            type="text"
            v-model="sellersTablesFilters.global.value"
            placeholder="Global Search"
          />
        </div>
      </template>
      <template #content>
        <DataTable
          :value="sellers"
          class="p-datatable-sm"
          :paginator="true"
          :rows="10"
          :filters="sellersTablesFilters"
          :sort-field="'id'"
          :sort-order="-1"
        >
          <Column field="partner_id" header="Partner" :sortable="true"></Column>
          <Column field="id" header="ID" :sortable="true"></Column>
          <Column field="name" header="Name" :sortable="true"></Column>
          <Column field="domain" header="Domain" :sortable="true">
            <template #body="slotProps">
              <a
                class="no-style-link"
                :href="formatDomainURL(slotProps.data.domain)"
                target="_blank"
              >
                {{ slotProps.data.domain }}
              </a>
            </template>
          </Column>

          <Column field="group_name" header="Group" :sortable="true"></Column>
          <Column field="adstxt_verified" header="ads.txt" :sortable="true">
            <template #body="slotProps">
              <AdstxtValResultTag :data="slotProps.data" />
            </template>
          </Column>
          <Column field="status" header="Status" :sortable="true">
            <template #body="slotProps">
              <Badge
                :severity="
                  slotProps.data.status === 'approved'
                    ? 'success'
                    : slotProps.data.status === 'declined'
                    ? 'danger'
                    : 'warning'
                "
                :value="slotProps.data.status"
                class="capitalize border-round-3xl"
              />
            </template>
          </Column>
          <Column>
            <template #body="slotProps">
              <div class="flex gap-1 justify-content-end">
                <Button
                  v-if="slotProps.data.status == 'approved'"
                  icon="pi pi-code"
                  outlined
                  rounded
                  severity="success"
                  @click="openGenerateTagDialog(slotProps.data)"
                />
                <Button
                  icon="pi pi-check"
                  class="p-button-rounded p-button-success p-button-outlined"
                  @click="confirmBeforeAction(slotProps.data.id, true)"
                  :disabled="slotProps.data.status === 'approved'"
                  aria-label="Approve"
                />
                <Button
                  icon="pi pi-times"
                  class="p-button-rounded p-button-danger p-button-outlined"
                  @click="confirmBeforeAction(slotProps.data.id, false)"
                  :disabled="slotProps.data.status === 'declined'"
                  aria-label="Decline"
                />
                <Button
                  icon="pi pi-pencil"
                  class="p-button-rounded p-button-outlined"
                  @click="openEditDialog(slotProps.data)"
                  aria-label="Edit"
                />
                <Button
                  icon="pi pi-trash"
                  outlined
                  rounded
                  severity="danger"
                  @click="ConfirmSellersDelete(slotProps.data)"
                  aria-label="Delete"
                />
              </div>
            </template>
          </Column>
        </DataTable>
      </template>
    </Card>
  </div>

  <Dialog
    v-model:visible="editDialogVisible"
    @hide="closeEditDialog"
    :style="{ width: '75vw' }"
  >
    <AdminSellerEdit
      :sellerid="selectedSeller.id"
      :partnerid="selectedSeller.partner_id"
      :tagbl="selectedSeller.tags_bl"
      :tagwl="selectedSeller.tags_wl"
      :isdemo="
        configObject[selectedSeller.partner_id]?.bbp_disable === 1 ||
        configObject[`${selectedSeller.partner_id}_${selectedSeller.id}`]
          ?.bbp_disable === 1
      "
      @update-success="handleUpdateSuccess"
      @close-dialog="closeEditDialog"
    />
  </Dialog>
  <Dialog
    v-model:visible="selectPartnerDialogVisible"
    class="w-screen max-w-30rem"
    @hide="closeSelectPartner"
    :closable="true"
    modal
  >
    <div class="flex flex-column">
      <label for="selectpartner">Select Partner</label>
      <Dropdown
        id="selectpartner"
        v-model="selectedPartner"
        :options="partners"
        placeholder="Select Partner"
        optionLabel="name"
        optionValue="id"
      />
    </div>
    <div class="pt-4 w-full flex justify-content-end">
      <Button
        size="small"
        class="justify-content-center"
        label="Add New Seller"
        icon="pi pi-plus"
        @click="goToAddNewSeller"
      />
    </div>
  </Dialog>
  <GenerateTagDialog
    v-model:visible="showGenerateTagDialog"
    :selectedSeller="selectedSeller"
    :partner="selectedPartner"
    @close-dialog="showGenerateTagDialog = false"
    @hide="showGenerateTagDialog = false"
    :adsTxtContent="adsTxtContent"
  />
</template>

<script>
// The rest of the code goes here
import { ref, onMounted, watch, computed } from "vue";
import { useStore } from "vuex";
import axios from "axios";
import { useRouter } from "vue-router";
import { API_BASE_URL } from "../consts";
import { FilterMatchMode } from "primevue/api";
import Card from "primevue/card";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import Badge from "primevue/badge";
import { useToast } from "primevue/usetoast";
import { useConfirm } from "primevue/useconfirm";
import Message from "primevue/message";
import AdstxtValResultTag from "./AdstxtValResult/AdstxtValResultTag.vue";
import Dialog from "primevue/dialog";
import AdminSellerEdit from "./AdminSellerEdit.vue";
import { isWithinOneWeek } from "../helpers";
import Dropdown from "primevue/dropdown";
import GenerateTagDialog from "@/components/GenerateTagDialog.vue";
import Panel from "primevue/panel";

export default {
  components: {
    InputText,
    Card,
    Button,
    Badge,
    AdstxtValResultTag,
    Dialog,
    AdminSellerEdit,
    Message,
    Dropdown,
    GenerateTagDialog,
    Panel,
  },
  setup() {
    const sellers = ref([]);
    const partners = ref([]);
    const store = useStore();
    const confirm = useConfirm();
    const toast = useToast();
    const router = useRouter();
    const sellersTablesFilters = ref({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    const title = "Admin Sellers";
    const token = localStorage.getItem("adminToken");

    const editDialogVisible = ref(false);
    const selectPartnerDialogVisible = ref(false);
    const showGenerateTagDialog = ref(false);
    const selectedSeller = ref({});
    const pendingSellersCount = ref(0);
    const configObject = ref({});
    const selectedPartner = ref(null);
    const adsTxtContent = ref([]);
    const recentlyVerifiedSellers = ref([]);
    const isRecentVerifiedExpanded = ref(false);

    const recentlyVerifiedSellersArray = computed(() => {
      return Array.isArray(recentlyVerifiedSellers.value)
        ? recentlyVerifiedSellers.value
        : Object.values(recentlyVerifiedSellers.value || {});
    });

    const toggleRecentVerified = () => {
      isRecentVerifiedExpanded.value = !isRecentVerifiedExpanded.value;
      if (isRecentVerifiedExpanded.value) {
        console.log(
          "Expanded. Recent verified sellers:",
          recentlyVerifiedSellers.value
        );
      }
    };

    const pendingSellers = ref([]);
    const isPendingSellersExpanded = ref(false);

    const togglePendingSellers = () => {
      isPendingSellersExpanded.value = !isPendingSellersExpanded.value;
    };

    const openGenerateTagDialog = (seller) => {
      selectedPartner.value = partners.value.find(
        (item) => item.id == seller.partner_id
      );
      selectedSeller.value = seller;
      showGenerateTagDialog.value = true;
    };

    const goToAddNewSeller = () => {
      if (!selectedPartner.value) return;

      router.push({
        name: "AdminAddSeller",
        query: { partnerId: selectedPartner.value },
      });
    };

    const openSelectPartner = () => {
      selectedPartner.value = null;
      selectPartnerDialogVisible.value = true;
    };

    const closeSelectPartner = () => {
      selectedPartner.value = null;
      selectPartnerDialogVisible.value = false;
    };

    const openEditDialog = (seller) => {
      selectedSeller.value = seller;
      editDialogVisible.value = true;
    };

    const closeEditDialog = () => {
      editDialogVisible.value = false;
    };

    const handleUpdateSuccess = () => {
      closeEditDialog();
      //fetchSellers(); // Refresh the sellers list
    };

    const goToEdit = (seller_id, partner_id, tags_bl, tags_wl) => {
      router.push({
        name: "AdminSellerEdit",
        query: {
          sellerid: seller_id,
          partnerid: partner_id,
          tags_bl,
          tags_wl,
        },
      });
    };

    const deleteSeller = async (idToDel) => {
      try {
        store.commit("setLoading", true);

        const sellerIds = [idToDel];

        const response = await axios.delete(API_BASE_URL + "/sellers", {
          params: { sellerIds: JSON.stringify(sellerIds) },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.data.success) {
          toast.add({
            severity: "info",
            summary: "Confirmed",
            detail: `Seller has been deleted`,
            life: 4000,
          });
          fetchSellers();
        }
      } catch (error) {
        console.error("Error deleting seller:", error);
        toast.add({
          severity: "error",
          summary: "Error",
          detail: "Something went wrong...",
          life: 4000,
        });
      } finally {
        store.commit("setLoading", false);
      }
    };

    const ConfirmSellersDelete = (sellerToDel) => {
      confirm.require({
        message: "Do you want to delete this seller?",
        header: "Delete Confirmation",
        icon: "pi pi-info-circle",
        acceptClass: "p-button-danger",
        accept: () => {
          deleteSeller(sellerToDel.id);
        },
      });
    };

    const updateSellerStatus = async (sellerId, status) => {
      try {
        const response = await axios.put(
          API_BASE_URL + "/admin/sellers/" + sellerId + "/status",
          { status: status },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status == 200) {
          // update the status in the local data
          const seller = sellers.value.find((s) => s.id === sellerId);
          if (seller) {
            seller.status = status;
            toast.add({
              severity: "info",
              summary: `Seller ${status}`,
              detail: `Seller has been ${status} successfully.`,
              life: 4000,
            });
          }
        }
      } catch (error) {
        console.error(`Error updating seller status to ${status}:`, error);
      }
    };

    const confirmBeforeAction = (sellerId, actionType) => {
      confirm.require({
        message: `Are you sure you want to ${
          actionType ? "approve" : "decline"
        } this connection?`,
        header: "Confirmation",
        icon: "pi pi-info-circle",
        acceptIcon: `pi pi-${actionType ? "check-circle" : "ban"}`,
        acceptClass: `p-button-${actionType ? "info" : "danger"}`,
        acceptLabel: actionType ? "Approve" : "Decline",
        accept: () => {
          updateSellerStatus(sellerId, actionType ? "approved" : "declined");
        },
      });
    };

    const fetchConfig = async () => {
      try {
        store.commit("setLoading", true);
        const resFromDB = await axios.get(API_BASE_URL + "/get-config", {
          headers: { Authorization: `Bearer ${token}` },
        });
        configObject.value = resFromDB.data;
      } catch (error) {
        console.log(error);
      } finally {
        store.commit("setLoading", false);
      }
    };

    const fetchAdsTxt = async () => {
      try {
        const response = await axios.get(API_BASE_URL + "/adstxt-rows", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.data.result) {
          adsTxtContent.value = response.data.result;
        }
      } catch (error) {
        console.log(error);
      }
    };

    const fetchPartners = async () => {
      try {
        store.commit("setLoading", true);
        const response = await axios.get(API_BASE_URL + "/partners", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.data) {
          partners.value = response.data;
        }
      } catch (error) {
        console.error("Error fetching partners:", error);
      } finally {
        store.commit("setLoading", false);
      }
    };
    const fetchSellers = async () => {
      try {
        store.commit("setLoading", true);

        const response = await axios.get(API_BASE_URL + "/sellers", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.data) {
          sellers.value = response.data;

          // Counting pending sellers
          pendingSellers.value = sellers.value.filter(
            (item) =>
              item.status === "pending" && isWithinOneWeek(item.updated_at)
          );

          // List of sellers with ads.txt verified in the last week
          recentlyVerifiedSellers.value = sellers.value.filter(
            (item) =>
              item.adstxt_verified_at &&
              isWithinOneWeek(item.adstxt_verified_at)
          );
        }
      } catch (error) {
        console.error("Error fetching sellers:", error);
      } finally {
        store.commit("setLoading", false);
      }
    };

    const formatDomainURL = (domain) => {
      if (domain.startsWith("http://") || domain.startsWith("https://")) {
        return domain;
      }
      return `https://${domain}`;
    };

    watch(
      recentlyVerifiedSellers,
      (newValue) => {
        console.log("recentlyVerifiedSellers changed:", newValue);
      },
      { deep: true }
    );

    onMounted(fetchSellers);
    onMounted(fetchPartners);
    onMounted(fetchConfig);
    onMounted(fetchAdsTxt);

    return {
      sellers,
      confirmBeforeAction,
      goToAddNewSeller,
      openSelectPartner,
      closeSelectPartner,
      goToEdit,
      sellersTablesFilters,
      title,
      editDialogVisible,
      selectPartnerDialogVisible,
      selectedSeller,
      openEditDialog,
      closeEditDialog,
      handleUpdateSuccess,
      formatDomainURL,
      pendingSellersCount,
      configObject,
      selectedPartner,
      partners,
      selectedSeller,
      showGenerateTagDialog,
      openGenerateTagDialog,
      adsTxtContent,
      recentlyVerifiedSellers,
      toggleRecentVerified,
      isRecentVerifiedExpanded,
      recentlyVerifiedSellersArray,
      pendingSellers,
      isPendingSellersExpanded,
      togglePendingSellers,
      ConfirmSellersDelete,
    };
  },
};
</script>

<style>
.blurred-background {
  filter: blur(2px);
  /* Add more styling as needed to achieve the desired effect */
}
.no-style-link {
  color: inherit; /* Makes the link color the same as the surrounding text */
  text-decoration: none; /* Removes the underline from the link */
}
.custom-expandable-section {
  border: 1px solid #ced4da;
  border-radius: 6px;
  overflow: hidden;
  margin-bottom: 1rem; /* Add some space between the two expandable sections */
}

.custom-expandable-section .p-button {
  border-radius: 0;
}

.expandable-content {
  padding: 1rem;
  background-color: transparent; /* Changed from #f8f9fa to transparent */
}

/* Add these new styles */
.expandable-content ul {
  margin-top: 0.5rem;
}

.expandable-content li {
  padding: 0.5rem 0;
  border-bottom: 1px solid #e9ecef;
}

.expandable-content li:last-child {
  border-bottom: none;
}

.p-datatable .p-datatable-thead > tr > th {
  padding: 0.2rem 1rem;
  font-size: 0.9rem;
}

.p-datatable .p-datatable-tbody > tr > td {
  padding: 0.1rem 1rem;
  font-size: 0.85rem;
}
.p-datatable.p-datatable-sm .p-datatable-tbody > tr > td {
  padding: 0.2rem 0.5rem;
}

.p-datatable .p-datatable-tbody > tr {
  height: 2rem;
}

.p-datatable .p-column-title {
  font-weight: 600;
}

/* Adjust button sizes in the table */
.p-datatable .p-button.p-button-icon-only {
  width: 2rem;
  height: 2rem;
}

.p-datatable .p-badge {
  font-size: 0.7rem;
  padding: 0.05rem 0.3rem;
}

/* Adjust input field in the header */
.p-datatable .p-inputtext {
  font-size: 0.9rem;
  padding: 0.4rem 0.75rem;
}
</style>
