<template>
  <div class="podcaster-review">
    <h2 class="mt-0" style="color: var(--text-color)">Podcaster Review</h2>
    <div v-if="podcaster">
      <Card>
        <template #title>Information </template>
        <template #content>
          <div class="p-d-flex p-flex-column">
            <div>
              <strong>ID: </strong>
              <a
                :href="`/#/podcast-admin/add-podcaster/${podcaster.podcaster_id}`"
                >{{ podcaster.podcaster_id }}</a
              >
            </div>
            <div><strong>Email:</strong> {{ podcaster.email }}</div>
            <div><strong>Type:</strong> {{ podcaster.podcaster_type }}</div>
            <div><strong>Plan:</strong> {{ podcaster.plan }}</div>
          </div>
        </template>
      </Card>

      <h2 style="color: var(--text-color)" class="mt-4">Shows</h2>
      <DataTable
        :value="shows"
        :paginator="true"
        :rows="10"
        :rowsPerPageOptions="[5, 10, 20]"
        responsiveLayout="scroll"
        :expandedRows="expandedRows"
        @update:expandedRows="onRowExpand"
        dataKey="show_id"
        class="p-datatable-sm"
      >
        <Column
          field="show_id"
          header="Show ID"
          :sortable="true"
          style="width: 15%"
        >
          <template #body="slotProps">
            <div class="truncate-text" :title="slotProps.data.show_id">
              <a
                :href="`/#/podcast-admin/add-show/${slotProps.data.show_id}`"
                >{{ slotProps.data.show_id }}</a
              >
            </div>
          </template>
        </Column>
        <Column
          field="show_title"
          header="Show Title"
          :sortable="true"
          style="width: 25%"
        >
          <template #body="slotProps">
            <div class="truncate-text" :title="slotProps.data.show_title">
              {{ slotProps.data.show_title }}
            </div>
          </template>
        </Column>
        <Column
          field="views"
          header="Views (yesterday)"
          :sortable="true"
          style="width: 15%"
        >
          <template #body="slotProps">
            <div
              class="truncate-text"
              :title="formatNumber(slotProps.data.views)"
            >
              {{ formatNumber(slotProps.data.views) }}
            </div>
          </template>
        </Column>
        <Column
          field="sessions"
          header="Sessions (yesterday)"
          :sortable="true"
          style="width: 15%"
        >
          <template #body="slotProps">
            <div
              class="truncate-text"
              :title="formatNumber(slotProps.data.sessions)"
            >
              {{ formatNumber(slotProps.data.sessions) }}
            </div>
          </template>
        </Column>
        <Column :expander="true" headerStyle="width: 3rem" />
        <template #expansion="slotProps">
          <div
            class="p-p-3"
            v-if="
              slotProps.data.campaigns && slotProps.data.campaigns.length > 0
            "
          >
            <h4>Campaigns</h4>
            <DataTable
              :value="slotProps.data.campaigns"
              responsiveLayout="scroll"
              class="p-datatable-sm"
            >
              <Column
                field="campaign_id"
                header="Campaign ID"
                :sortable="true"
                style="width: 15%"
              >
                <template #body="slotProps">
                  <div
                    class="truncate-text"
                    :title="slotProps.data.campaign_id"
                  >
                    <a
                      :href="`/#/podcast-admin/add-campaign/${slotProps.data.campaign_id}`"
                      >{{ slotProps.data.campaign_id }}</a
                    >
                  </div>
                </template>
              </Column>
              <Column
                field="start_date"
                header="Start Date"
                :sortable="true"
                class="date-column"
                style="width: 10%"
              >
                <template #body="slotProps">
                  <div
                    class="truncate-text"
                    :title="dateBodyTemplate(slotProps.data.start_date)"
                  >
                    {{ dateBodyTemplate(slotProps.data.start_date) }}
                  </div>
                </template>
              </Column>
              <Column
                field="end_date"
                header="End Date"
                :sortable="true"
                class="date-column"
                style="width: 10%"
              >
                <template #body="slotProps">
                  <div
                    class="truncate-text"
                    :title="dateBodyTemplate(slotProps.data.end_date)"
                  >
                    {{ dateBodyTemplate(slotProps.data.end_date) }}
                  </div>
                </template>
              </Column>
              <Column
                field="status"
                header="Status"
                :sortable="true"
                style="width: 10%"
              >
                <template #body="slotProps">
                  <div class="truncate-text" :title="slotProps.data.status">
                    {{ slotProps.data.status }}
                  </div>
                </template>
              </Column>
              <Column
                field="active"
                header="Active"
                :sortable="true"
                style="width: 8%"
              >
                <template #body="slotProps">
                  <div
                    class="truncate-text"
                    :title="activeBodyTemplate(slotProps.data)"
                  >
                    {{ activeBodyTemplate(slotProps.data) }}
                  </div>
                </template>
              </Column>
              <Column
                field="campaign_type"
                header="Type"
                :sortable="true"
                style="width: 10%"
              >
                <template #body="slotProps">
                  <div
                    class="truncate-text"
                    :title="slotProps.data.campaign_type"
                  >
                    {{ slotProps.data.campaign_type }}
                  </div>
                </template>
              </Column>
              <Column
                field="promotions_per_interval"
                header="Promotions per Interval"
                :sortable="true"
                style="width: 10%"
              >
                <template #body="slotProps">
                  <div
                    class="truncate-text"
                    :title="
                      formatNumber(slotProps.data.promotions_per_interval)
                    "
                  >
                    {{ formatNumber(slotProps.data.promotions_per_interval) }}
                  </div>
                </template>
              </Column>
              <Column
                field="ctr"
                header="CTR"
                :sortable="true"
                style="width: 8%"
              >
                <template #body="slotProps">
                  <div
                    class="truncate-text"
                    :title="`${formatNumber(slotProps.data.ctr, 2)}%`"
                  >
                    {{ formatNumber(slotProps.data.ctr, 2) }}%
                  </div>
                </template>
              </Column>
              <Column
                field="publisher_id"
                header="Publisher ID"
                :sortable="true"
                style="width: 12%"
              >
                <template #body="slotProps">
                  <div
                    class="truncate-text"
                    :title="slotProps.data.publisher_id"
                  >
                    {{ slotProps.data.publisher_id }}
                  </div>
                </template>
              </Column>
              <Column
                field="loads"
                header="Loads (yesterday)"
                :sortable="true"
                style="width: 10%"
              >
                <template #body="slotProps">
                  <div
                    class="truncate-text"
                    :title="formatNumber(slotProps.data.loads)"
                  >
                    {{ formatNumber(slotProps.data.loads) }}
                  </div>
                </template>
              </Column>
            </DataTable>
          </div>
          <div v-else class="p-p-3">No campaigns available for this show.</div>
        </template>
      </DataTable>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import axios from "axios";
import Card from "primevue/card";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import { API_BASE_URL } from "@/consts";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";

export default {
  name: "PodcasterReview",
  components: {
    Card,
    DataTable,
    Column,
  },
  setup() {
    const podcaster = ref(null);
    const shows = ref([]);
    const expandedRows = ref([]);
    const token = localStorage.getItem("podcastAdminToken");
    const route = useRoute();
    const store = useStore();

    const fetchPodcasterData = async () => {
      try {
        const response = await axios.get(
          `${API_BASE_URL}/podcaster-overview/${route.params.podcasterId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        podcaster.value = response.data.podcaster;
        shows.value = response.data.shows;
      } catch (error) {
        console.error("Error fetching podcaster review data:", error);
      }
    };

    onMounted(() => {
      fetchPodcasterData();
    });

    const dateBodyTemplate = (rowData) => {
      return new Date(rowData).toLocaleDateString();
    };

    const activeBodyTemplate = (rowData) => {
      const now = new Date();
      const startDate = new Date(rowData.start_date);
      const endDate = new Date(rowData.end_date);
      const isActive = startDate <= now && now <= endDate;
      return isActive ? "Yes" : "No";
    };

    const onRowExpand = (event) => {
      expandedRows.value = event;
    };

    const formatNumber = (value, decimals = 2) => {
      return new Intl.NumberFormat("en-US", {
        minimumFractionDigits: 0,
        maximumFractionDigits: decimals,
      }).format(value);
    };

    return {
      podcaster,
      shows,
      expandedRows,
      dateBodyTemplate,
      activeBodyTemplate,
      onRowExpand,
      formatNumber,
    };
  },
};
</script>

<style scoped>
.p-datatable .p-datatable-thead > tr > th,
.p-datatable .p-datatable-tbody > tr > td {
  padding: 0.5rem;
  font-size: 0.9rem;
  max-height: 3.5em;
  overflow: hidden;
}

.p-card .p-card-body {
  padding: 0.5rem;
}

.p-dropdown,
.p-inputtext,
.p-button.p-button-sm,
.p-calendar {
  font-size: 0.9rem;
}

.p-button.p-button-sm {
  padding: 0.3rem 0.5rem;
}

.truncate-text {
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.date-column {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100px; /* Adjust as needed */
}

a {
  color: inherit;
  text-decoration: underline;
}
</style>
