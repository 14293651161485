<template>
  <form @submit.prevent="submit">
    <div v-if="showCodeBox">
      <label for="resetcode" class="block text-900 font-medium mb-2"
        >Recovery Code</label
      >
      <InputText
        id="resetcode"
        v-model="resetCode"
        placeholder="Recovery Code"
        required
        @input="errorMessage = ''"
        type="text"
        autocomplete="new-code"
        class="w-full mb-3 p-3 text-900"
        style="background: #333; border: #333; box-shadow: none"
      />
      <label for="password1" class="block text-900 font-medium mb-2"
        >New Password</label
      >
      <InputText
        id="password1"
        type="password"
        autocomplete="new-password"
        v-model="password.first"
        placeholder="New Password"
        required
        @input="errorMessage = ''"
        class="w-full mb-3 p-3 text-900"
        style="background: #333; border: #333; box-shadow: none"
      />
      <label for="password1" class="block text-900 font-medium mb-2"
        >Confirm New Password</label
      >
      <InputText
        id="password2"
        type="password"
        v-model="password.second"
        placeholder="Confirm Password"
        required
        @input="errorMessage = ''"
        class="w-full mb-3 p-3 text-900"
        style="background: #333; border: #333; box-shadow: none"
      />
    </div>
    <InputText
      v-else
      v-model="userEmail"
      id="email"
      placeholder="Email"
      required
      @input="errorMessage = ''"
      type="text"
      class="w-full mb-3 p-3 text-900"
      style="background: #333; border: #333; box-shadow: none"
    />
    <div v-if="errorMessage.length" class="text-red-500 mb-4">
      {{ errorMessage }}
    </div>
    <Button
      type="submit"
      :disabled="isLoading"
      :label="showCodeBox ? 'Reset Password' : 'Get the Code'"
      :icon="`pi ${
        isLoading ? 'pi-spin pi-spinner' : showCodeBox ? 'pi-key' : 'pi-send'
      }`"
      class="w-full mt-4"
      style="background-color: #d9ff3f; border-color: #d9ff3f; color: #333"
    ></Button>
  </form>
</template>

<script setup>
import { ref } from "vue";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import { emailValidator } from "@/helpers";
import axios from "axios";
import { API_BASE_URL } from "@/consts";
import { useToast } from "primevue/usetoast";

const emit = defineEmits(["go-login"]);
const toast = useToast();
const userEmail = ref("");
const resetCode = ref("");
const errorMessage = ref("");
const showCodeBox = ref(false);
const isLoading = ref(false);
const password = ref({
  first: "",
  second: "",
});

const submit = async () => {
  if (showCodeBox.value) {
    if (resetCode.value.length !== 6) {
      errorMessage.value = "Please provide a valid code";
      return;
    }

    if (password.value.first !== password.value.second) {
      errorMessage.value = "Confirming password does not match";
      return;
    }

    isLoading.value = true;
    const response = await axios.post(`${API_BASE_URL}/reset-password`, {
      reset_code: resetCode.value,
      new_password: password.value.first,
    });
    isLoading.value = false;

    if (response.data.success) {
      toast.add({
        severity: "success",
        summary: "Success",
        detail: `Password reset successful. Please log in with your new password.`,
        life: 6000,
      });
      emit("go-login");
    } else {
      toast.add({
        severity: "error",
        detail: `Recovery code is not valid`,
        life: 4000,
      });
      errorMessage.value = "Please provide a valid code";
    }
  } else {
    if (!emailValidator(userEmail.value)) {
      errorMessage.value = "Please provide a valid email address";
      return;
    }

    isLoading.value = true;
    const response = await axios.post(
      `${API_BASE_URL}/get-reset-password-code`,
      {
        email: userEmail.value,
      }
    );
    isLoading.value = false;

    if (response.data.success) {
      toast.add({
        severity: "success",
        summary: "Success",
        detail: `The recovery code has been sent to your email address.`,
        life: 6000,
      });
      showCodeBox.value = true;
    } else {
      toast.add({
        severity: "error",
        detail: `Email address not found.`,
        life: 4000,
      });
      errorMessage.value =
        "Email address not found. Please check your entry or try a different email.";
    }
  }
};
</script>
