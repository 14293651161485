<template>
  <div class="p-2 md:p-4 lg:p-8">
    <h2 class="mt-0" style="color: var(--text-color)">Publisher Onboard</h2>
    <form @submit.prevent="submitForm">
      <Card class="mb-3">
        <template #title>
          <div class="text-xl">Media Group Details</div>
        </template>
        <template #content>
          <div class="grid">
            <div class="flex flex-column gap-1 col-12 md:col-6">
              <label for="groupName">Group Name</label>
              <InputText
                id="groupName"
                v-model="form.name"
                :class="{ 'p-invalid': submitted && !form.name }"
                placeholder="e.g. My Group"
              />
              <small v-if="submitted && !form.name" class="p-error"
                >Name is required</small
              >
            </div>
            <div class="flex flex-column gap-1 col-12 md:col-6">
              <label for="email">Email</label>
              <InputText
                id="email"
                v-model="form.email"
                :class="{
                  'p-invalid': submitted && (!form.email || !emailValid),
                }"
                placeholder="e.g. group@example.com"
                type="email"
                autocomplete="new-email"
              />
              <small v-if="submitted && !emailValid" class="p-error"
                >Invalid email format</small
              >
            </div>
            <div class="flex flex-column gap-1 col-12 md:col-6">
              <label for="password">Password</label>
              <InputText
                id="password"
                v-model="form.password"
                autocomplete="new-password"
                type="password"
                :class="{
                  'p-invalid': submitted && !passwordValid,
                }"
                placeholder="Password"
              />
              <small v-if="submitted && !passwordValid" class="p-error"
                >Passwords must be at least 8 characters long and include at
                least one uppercase letter.</small
              >
            </div>
            <div class="flex flex-column gap-1 col-12 md:col-6">
              <label for="company_domain">Company Domain: </label>
              <InputText
                id="company_domain"
                v-model="form.company_domain"
                placeholder="Company Domain"
                :class="{
                  'p-invalid': submitted && !domainValid,
                }"
              />
              <small v-if="submitted && !domainValid" class="p-error"
                >A valid domain is required</small
              >
            </div>
            <div class="flex flex-column gap-1 col-12 md:col-6">
              <label for="seller_type">Seller Type:</label>
              <Dropdown
                id="seller_type"
                v-model="form.seller_type"
                :options="sellerTypes"
                placeholder="Select Type"
                optionLabel="label"
                optionValue="value"
                :class="{
                  'p-invalid': submitted && !form.seller_type,
                }"
              />
              <small v-if="submitted && !form.seller_type" class="p-error"
                >Seller type is required</small
              >
            </div>
            <div class="flex flex-column gap-1 col-12 md:col-6">
              <label for="monetization_type">Monetization Type: </label>
              <Dropdown
                id="monetization_type"
                v-model="form.monetization_type"
                :options="monetizationTypes"
                placeholder="Select Monetization Type"
                optionLabel="label"
                optionValue="value"
                :class="{
                  'p-invalid': submitted && !form.monetization_type,
                }"
              />
              <small v-if="submitted && !form.monetization_type" class="p-error"
                >Monetization Type is required</small
              >
            </div>
          </div>
        </template>
      </Card>
      <Card class="mb-3">
        <template #title>
          <div class="text-xl">Monetization Details</div>
        </template>
        <template #content>
          <div class="grid">
            <div class="flex flex-column gap-1 col-12 md:col-6">
              <label for="average_us_monthly_page_view"
                >Average US Monthly Page Views</label
              >
              <InputText
                id="average_us_monthly_page_view"
                v-model="form.average_us_monthly_page_view"
                :class="{
                  'p-invalid': submitted && !form.average_us_monthly_page_view,
                }"
                placeholder=""
              />
              <small
                v-if="submitted && !form.average_us_monthly_page_view"
                class="p-error"
                >Average US Monthly Page Views required</small
              >
            </div>
            <div class="flex flex-column gap-1 col-12 md:col-6">
              <label for="top_geos">Publisher Top Geo's</label>
              <InputText
                id="top_geos"
                v-model="form.top_geos"
                :class="{
                  'p-invalid': submitted && !form.top_geos,
                }"
                placeholder="Top Geos"
              />
              <small v-if="submitted && !form.top_geos" class="p-error"
                >Publisher Top Geo's required</small
              >
            </div>
          </div>
        </template>
      </Card>
      <Card class="mb-3">
        <template #title>
          <div class="flex gap-4 align-items-center">
            <div class="text-xl">MCM Approval Details</div>
            <InputSwitch v-model="isMCM"></InputSwitch>
          </div>
        </template>
        <template v-if="isMCM" #content>
          <div class="grid">
            <div class="flex flex-column gap-1 col-12 md:col-6">
              <label for="gam_network_id">GAM Network ID</label>
              <InputText
                id="gam_network_id"
                v-model="form.gam_network_id"
                :class="{
                  'p-invalid': submitted && !form.gam_network_id,
                }"
                placeholder="GAM Network ID"
              />
              <small v-if="submitted && !form.gam_network_id" class="p-error"
                >GAM Network ID required</small
              >
            </div>
            <div class="flex flex-column gap-1 col-12 md:col-6">
              <label for="gam_company_name">GAM Company Name</label>
              <InputText
                id="gam_company_name"
                v-model="form.gam_company_name"
                :class="{
                  'p-invalid': submitted && !form.gam_company_name,
                }"
                placeholder="GAM Company Name"
              />
              <small v-if="submitted && !form.gam_company_name" class="p-error"
                >GAM Company Name required</small
              >
            </div>
            <div class="flex flex-column gap-1 col-12 md:col-6">
              <label for="mcm_contact_email">Contact Email for MCM</label>
              <InputText
                id="mcm_contact_email"
                v-model="form.mcm_contact_email"
                :class="{
                  'p-invalid': submitted && !form.mcm_contact_email,
                }"
                placeholder="Contact Email for MCM"
              />
              <small v-if="submitted && !form.mcm_contact_email" class="p-error"
                >Contact Email for MCM required</small
              >
            </div>
            <div class="flex flex-column gap-1 col-12 md:col-6">
              <label for="mcm_domains">Publisher Domains</label>
              <InputText
                id="mcm_domains"
                v-model="form.mcm_domains"
                :class="{
                  'p-invalid': submitted && !form.mcm_domains,
                }"
                placeholder="Publisher Domains"
              />
              <small v-if="submitted && !form.mcm_domains" class="p-error"
                >Publisher Domains required</small
              >
            </div>
          </div>
        </template>
      </Card>
      <Card class="mb-3">
        <template #title>
          <div class="flex gap-4 align-items-center">
            <div class="text-xl">Add Podcast</div>
            <InputSwitch v-model="podcasterForm.add_podcast"></InputSwitch>
          </div>
        </template>
        <template v-if="podcasterForm.add_podcast" #content>
          <div class="grid">
            <div class="flex flex-column gap-1 col-12 md:col-6">
              <label for="rss_url">RSS url</label>
              <InputText
                id="rss_url"
                v-model="podcasterForm.rss_url"
                :class="{
                  'p-invalid': submitted && !podcasterForm.rss_url,
                }"
                placeholder="RSS url"
              />
              <small v-if="submitted && !podcasterForm.rss_url" class="p-error"
                >RSS url required</small
              >
            </div>
            <div class="flex flex-column gap-1 col-12 md:col-6">
              <label for="avg_per_month">Avg Downloads Per Month</label>
              <InputText
                id="avg_per_month"
                v-model="podcasterForm.avg_per_month"
                :class="{
                  'p-invalid': submitted && !podcasterForm.avg_per_month,
                }"
                placeholder="Avg Downloads Per Month"
              />
              <small
                v-if="submitted && !podcasterForm.avg_per_month"
                class="p-error"
                >Avg Downloads Per Month required</small
              >
            </div>
            <div class="flex flex-column gap-1 col-12 md:col-6">
              <label for="avg_per_episode">Avg Downloads Per Episode</label>
              <InputText
                id="avg_per_episode"
                v-model="podcasterForm.avg_per_episode"
                placeholder="Avg Downloads Per Episode"
              />
            </div>
            <div class="flex flex-column gap-1 col-12 md:col-6">
              <label for="spotify_link">Spotify link</label>
              <InputText
                id="spotify_link"
                v-model="podcasterForm.spotify_link"
                placeholder="Spotify link"
              />
            </div>
            <div class="flex flex-column gap-1 col-12 md:col-6">
              <label for="itunes_link">iTunes link</label>
              <InputText
                id="itunes_link"
                v-model="podcasterForm.itunes_link"
                placeholder="iTunes link"
              />
            </div>
            <div class="flex flex-column gap-1 col-12 md:col-6">
              <label for="promotion">Promotion</label>
              <InputSwitch
                inputId="promotion"
                v-model="podcasterForm.promotion"
              ></InputSwitch>
            </div>
          </div>
        </template>
      </Card>
      <Card class="mb-3">
        <template #title>
          <div class="text-xl">Contact Information</div>
        </template>
        <template #content>
          <div class="grid">
            <div class="col-12 md:col-6 flex flex-column gap-3 mb-3">
              <div class="flex flex-column gap-1 w-full">
                <label for="company_name">Company Name</label>
                <InputText
                  id="company_name"
                  v-model="form.company_name"
                  placeholder="Company Name"
                  :class="{
                    'p-invalid': submitted && !form.company_name,
                  }"
                />
                <small v-if="submitted && !form.company_name" class="p-error"
                  >Company name is required</small
                >
              </div>
              <div class="flex flex-column gap-1 w-full">
                <label for="company_vat_number">Number/VAT</label>
                <InputText
                  id="company_vat_number"
                  v-model="form.company_vat_number"
                  placeholder="Number/VAT"
                  :class="{
                    'p-invalid': submitted && !form.company_vat_number,
                  }"
                />
                <small
                  v-if="submitted && !form.company_vat_number"
                  class="p-error"
                  >Number/VAT is required</small
                >
              </div>
              <div class="flex flex-column gap-1 w-full">
                <label for="company_address">Company Address</label>
                <InputText
                  id="company_address"
                  v-model="form.company_address"
                  placeholder="Company Address"
                />
              </div>
              <div class="flex flex-column gap-1 w-full">
                <label for="company_country_of_registration"
                  >Company Country</label
                >
                <InputText
                  id="company_country_of_registration"
                  v-model="form.company_country_of_registration"
                  placeholder="Company Country"
                />
              </div>
              <div class="flex flex-column gap-1 w-full">
                <label for="company_address_city">Company City</label>
                <InputText
                  id="company_address_city"
                  v-model="form.company_address_city"
                  placeholder="Company City"
                />
              </div>
              <div class="flex flex-column gap-1 w-full">
                <label for="company_address_zip_code">Company ZipCode</label>
                <InputText
                  id="company_address_zip_code"
                  v-model="form.company_address_zip_code"
                  placeholder="Company ZipCode"
                />
              </div>
            </div>
            <div class="col-12 md:col-6 flex flex-column gap-3 mb-3">
              <div class="flex flex-column gap-1 w-full">
                <label for="contact_person_name">Contact Person Name</label>
                <InputText
                  id="contact_person_name"
                  v-model="form.contact_person_name"
                  placeholder="Contact Person Name"
                />
              </div>
              <div class="flex flex-column gap-1 w-full">
                <label for="contact_person_title">Contact Person Title</label>
                <InputText
                  id="contact_person_title"
                  v-model="form.contact_person_title"
                  placeholder="Contact Person Title"
                />
              </div>
              <div class="flex flex-column gap-1 w-full">
                <label for="contact_person_email">Contact Person Email</label>
                <InputText
                  id="contact_person_email"
                  v-model="form.contact_person_email"
                  placeholder="Contact Person Email"
                />
              </div>
              <div class="flex flex-column gap-1 w-full">
                <label for="contact_person_phone_number"
                  >Contact Person Phone</label
                >
                <InputText
                  id="contact_person_phone_number"
                  v-model="form.contact_person_phone_number"
                  placeholder="Contact Person Phone"
                />
              </div>
              <div class="flex flex-column gap-1 w-full">
                <label for="contact_person_mobile_number"
                  >Contact Person Mobile</label
                >
                <InputText
                  id="contact_person_mobile_number"
                  v-model="form.contact_person_mobile_number"
                  placeholder="Contact Person Mobile"
                />
              </div>
            </div>
            <div class="col-12 md:col-6 flex flex-column gap-3">
              <div class="flex flex-column gap-1 w-full">
                <label for="finance_contact_name">Finance Contact</label>
                <InputText
                  id="finance_contact_name"
                  v-model="form.finance_contact_name"
                  placeholder="Finance Contact"
                />
              </div>
              <div class="flex flex-column gap-1 w-full">
                <label for="accounting_dept_email">Accounting Email</label>
                <InputText
                  id="accounting_dept_email"
                  v-model="form.accounting_dept_email"
                  placeholder="Accounting Email"
                />
              </div>
              <div class="flex flex-column gap-1 w-full">
                <label for="accounting_dept_phone_number"
                  >Accounting Phone Number</label
                >
                <InputText
                  id="accounting_dept_phone_number"
                  v-model="form.accounting_dept_phone_number"
                  placeholder="Accounting Phone Number"
                />
              </div>
              <div class="flex flex-column gap-1 w-full">
                <label for="payment_term">Payment Term</label>
                <InputText
                  id="payment_term"
                  v-model="form.payment_term"
                  placeholder="Payment Term"
                />
              </div>
            </div>
            <div class="col-12 md:col-6 flex flex-column gap-3"></div>
          </div>
        </template>
      </Card>
      <Card class="mb-3">
        <template #title>
          <div class="text-xl">Publisher Bank Details</div>
        </template>
        <template #content>
          <div class="grid">
            <div class="flex flex-column gap-1 col-12 md:col-6">
              <label for="bank_name">Bank (Name + Code)</label>
              <InputText
                id="bank_name"
                v-model="form.bank_name"
                :class="{
                  'p-invalid': submitted && !form.bank_name,
                }"
                placeholder="Bank (Name + Code)"
              />
              <small v-if="submitted && !form.bank_name" class="p-error"
                >Bank (Name + Code) required</small
              >
            </div>
            <div class="flex flex-column gap-1 col-12 md:col-6">
              <label for="bank_branch_code">Branch Code</label>
              <InputText
                id="bank_branch_code"
                v-model="form.bank_branch_code"
                :class="{
                  'p-invalid': submitted && !form.bank_branch_code,
                }"
                placeholder="Branch Code"
              />
              <small v-if="submitted && !form.bank_branch_code" class="p-error"
                >Branch Code required</small
              >
            </div>
            <div class="flex flex-column gap-1 col-12 md:col-6">
              <label for="bank_account_number">Account Number</label>
              <InputText
                id="bank_account_number"
                v-model="form.bank_account_number"
                :class="{
                  'p-invalid': submitted && !form.bank_account_number,
                }"
                placeholder="Account Number"
              />
              <small
                v-if="submitted && !form.bank_account_number"
                class="p-error"
                >Account Number required</small
              >
            </div>
            <div class="flex flex-column gap-1 col-12 md:col-6">
              <label for="bank_iban">IBAN</label>
              <InputText
                id="bank_iban"
                v-model="form.bank_iban"
                :class="{
                  'p-invalid': submitted && !form.bank_iban,
                }"
                placeholder="IBAN"
              />
              <small v-if="submitted && !form.bank_iban" class="p-error"
                >IBAN required</small
              >
            </div>
            <div class="flex flex-column gap-1 col-12 md:col-6">
              <label for="bank_swift">SWIFT</label>
              <InputText
                id="bank_swift"
                v-model="form.bank_swift"
                :class="{
                  'p-invalid': submitted && !form.bank_swift,
                }"
                placeholder="SWIFT"
              />
              <small v-if="submitted && !form.bank_swift" class="p-error"
                >SWIFT required</small
              >
            </div>
          </div>
        </template>
      </Card>
      <div class="w-full flex justify-content-end gap-2">
        <Button
          class="flex-1 sm:flex-initial"
          outlined
          label="Go to Login"
          @click="router.push('/')"
        />
        <Button class="flex-1 sm:flex-initial" type="submit" label="Submit" />
      </div>
    </form>
  </div>
</template>

<script>
import { ref, onBeforeMount } from "vue";
import InputText from "primevue/inputtext";
import Card from "primevue/card";
import Dropdown from "primevue/dropdown";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { useToast } from "primevue/usetoast";

import Button from "primevue/button";
import SelectButton from "primevue/selectbutton";
import axios from "axios";
import { API_BASE_URL } from "../consts";
import InputSwitch from "primevue/inputswitch";

export default {
  components: {
    InputText,
    Button,
    Card,
    Dropdown,
    SelectButton,
    InputSwitch,
  },

  setup() {
    const store = useStore();
    const toast = useToast();
    const router = useRouter();

    const submitted = ref(false);

    const emailValid = ref(true);
    const domainValid = ref(true);
    const passwordValid = ref(true);
    const isMCM = ref(false);

    const token = router.currentRoute.value.query.token;

    const contractTypes = [
      { label: "Per Load", value: "cpm" },
      { label: "Revenue Share", value: "revshare" },
    ];

    const BoolTypes = [
      { label: "Disable", value: false },
      { label: "Allow", value: true },
    ];

    const sellerTypes = [
      { label: "Publisher", value: "PUBLISHER" },
      { label: "Intermediary", value: "INTERMEDIARY" },
      { label: "Both", value: "BOTH" },
    ];

    const monetizationTypes = [
      { label: "Audio Only", value: "audio" },
      { label: "Audio & Display", value: "audio_display" },
      { label: "Audio, Display & Video", value: "audio_display_video" },
    ];

    const form = ref({
      name: "",
      email: "",
      password: "",
      company_name: "",
      company_address: "",
      company_country_of_registration: "",
      company_address_city: "",
      company_address_zip_code: "",
      contact_person_name: "",
      contact_person_title: "",
      contact_person_email: "",
      contact_person_phone_number: "",
      contact_person_mobile_number: "",
      finance_contact_name: "",
      accounting_dept_email: "",
      accounting_dept_phone_number: "",
      payment_term: "",
      default_contract_type: contractTypes[0].value,
      default_contract_value: 0,
      show_gross_profit: BoolTypes[0].value,
      can_add_sellers: BoolTypes[0].value,
      company_domain: "",
      seller_type: "PUBLISHER",
      display_id: "",
      monetization_type: "",
      average_us_monthly_page_view: "",
      top_geos: "",
      gam_network_id: "",
      gam_company_name: "",
      mcm_contact_email: "",
      mcm_domains: "",
      company_vat_number: "",
      bank_name: "",
      bank_branch_code: "",
      bank_account_number: "",
      bank_iban: "",
      bank_swift: "",
    });

    const podcasterForm = ref({
      add_podcast: false,
      rss_url: "",
      spotify_link: "",
      itunes_link: "",
      avg_per_episode: 0,
      avg_per_month: 0,
      promotion: false,
    });

    const validateEmail = () => {
      const re =
        // eslint-disable-next-line no-useless-escape
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      emailValid.value = re.test(String(form.value.email).toLowerCase());
    };

    const validateDomain = () => {
      // eslint-disable-next-line no-useless-escape
      const re =
        /^(?=.{1,253}\.?$)(?:(?!-|[^.]+_)[A-Za-z0-9-_]{1,63}(?<!-)(?:\.|$)){2,}$/;
      domainValid.value = re.test(
        String(form.value.company_domain).toLowerCase()
      );
    };

    const validatePassword = () => {
      passwordValid.value =
        form.value.password &&
        form.value.password.length >= 8 &&
        /[A-Z]/.test(form.value.password);
    };

    const submitForm = async () => {
      submitted.value = true;
      validateEmail();
      validateDomain();
      validatePassword();

      if (
        form.value.name?.length &&
        form.value.email?.length &&
        form.value.monetization_type?.length &&
        form.value.company_name?.length &&
        form.value.company_domain?.length &&
        form.value.company_vat_number?.length &&
        emailValid.value &&
        domainValid.value &&
        passwordValid.value &&
        form.value.average_us_monthly_page_view?.length &&
        form.value.top_geos?.length &&
        (!isMCM.value ||
          (isMCM.value &&
            form.value.gam_network_id?.length &&
            form.value.gam_company_name?.length &&
            form.value.mcm_contact_email?.length &&
            form.value.mcm_domains?.length)) &&
        (!podcasterForm.value.add_podcast ||
          (podcasterForm.value.add_podcast &&
            podcasterForm.value.rss_url?.length &&
            podcasterForm.value.avg_per_month > 0)) &&
        form.value.bank_name?.length &&
        form.value.bank_account_number?.length &&
        form.value.bank_branch_code?.length &&
        form.value.bank_iban?.length &&
        form.value.bank_swift?.length
      ) {
        let response;
        try {
          store.commit("setLoading", true);

          response = await axios.post(`${API_BASE_URL}/publisher_onboarding`, {
            groupData: form.value,
            podcasterData: podcasterForm.value,
            linkToken: token,
          });

          if (response.data?.success) {
            toast.add({
              severity: "success",
              summary: "Success",
              detail: `Publisher submitted successfully!`,
              life: 4000,
            });
            router.push("/");
            // Show a success message, or redirect to another page, etc.
          } else {
            // Handle unsuccessful submission
            toast.add({
              severity: "error",
              summary: "Error",
              detail: response?.data?.message || `Something went wrong...`,
              life: 4000,
            });
          }
        } catch (error) {
          // Handle errors
          toast.add({
            severity: "error",
            summary: "Error",
            detail: error.response?.data?.message || `Something went wrong...`,
            life: 4000,
          });
          console.log(error);
        } finally {
          store.commit("setLoading", false);
        }
      }
    };

    onBeforeMount(() => {
      if (!localStorage.getItem("loggedout")) {
        localStorage.clear();
        localStorage.setItem("loggedout", true);
        window.location.reload();
      }
    });

    return {
      form,
      podcasterForm,
      submitForm,
      router,
      submitted,
      emailValid,
      domainValid,
      contractTypes,
      monetizationTypes,
      passwordValid,
      BoolTypes,
      sellerTypes,
      isMCM,
    };
  },
};
</script>
