<template>
  <div>
    <h2 class="mt-0" style="color: var(--text-color)">{{ pageTitle }}</h2>

    <Card>
      <template #header>
        <div
          class="p-3 pb-0 flex flex-column sm:flex-row justify-content-between gap-3"
        >
          <Button
            size="small"
            class="justify-content-center"
            label="Add New Link"
            icon="pi pi-plus"
            @click="openNewLinkModal"
          />
          <InputText
            class="w-full sm:w-24rem"
            type="text"
            v-model="linksTablesFilters.global.value"
            placeholder="Global Search"
          ></InputText>
        </div>
      </template>
      <template #content>
        <DataTable
          :value="links"
          class="p-datatable-sm"
          :paginator="true"
          :rows="10"
          :filters="linksTablesFilters"
          :global-filter-fields="['id', 'partner_id', 'group_id', 'name']"
          :loading="tableLoading"
        >
          <Column field="id" header="ID" :sortable="true"></Column>
          <Column field="name" header="Name" :sortable="true"></Column>
          <Column field="url" header="URL">
            <template #body="slotProps">
              <Button
                label="Copy URL"
                icon="pi pi-copy"
                iconPos="right"
                severity="help"
                size="small"
                :disabled="slotProps.data.status != 'Valid'"
                outlined
                @click="copyURLToClipboard(slotProps.data.url)"
              />
            </template>
          </Column>
          <Column field="partner_id" header="Partner">
            <template #body="slotProps">
              {{
                partners.find((item) => item.id == slotProps.data.partner_id)
                  ?.name || slotProps.data.partner_id
              }}
            </template>
          </Column>
          <Column field="group_id" header="Group">
            <template #body="slotProps">
              {{
                groups.find((item) => item.id == slotProps.data.group_id)
                  ?.name || slotProps.data.group_id
              }}
            </template>
          </Column>
          <Column field="status" header="Status" :sortable="true">
            <template #body="slotProps">
              <Tag
                :severity="
                  slotProps.data.status == 'Completed'
                    ? 'success'
                    : slotProps.data.status == 'Valid'
                    ? 'info'
                    : 'danger'
                "
                :value="slotProps.data.status"
              ></Tag>
            </template>
          </Column>
          <Column>
            <template #body="slotProps">
              <div class="flex gap-1 justify-content-end">
                <Button
                  icon="pi pi-trash"
                  outlined
                  rounded
                  size="small"
                  severity="danger"
                  @click="(e) => confirmLinkDelete(e, slotProps.data.id)"
                ></Button>
              </div>
            </template>
          </Column>
          <template #paginatorstart>
            <Button type="button" icon="pi pi-refresh" @click="fetchLinks" text
          /></template>
        </DataTable>
      </template>
    </Card>
    <Dialog
      v-model:visible="showNewLinkModal"
      modal
      header="Create Link"
      :style="{ width: '35rem', maxWidth: '96vw' }"
    >
      <span class="text-surface-500 dark:text-surface-400 block mb-3"
        >Provide the link details.</span
      >
      <div class="grid mb-3">
        <div class="flex flex-column gap-1 col-12 md:col-6">
          <label for="selectpartner" class="font-semibold">Partner</label>
          <Dropdown
            id="selectpartner"
            v-model="newLinkDetail.partner_id"
            :options="partners"
            class="w-full"
            placeholder="Select Partner"
            optionLabel="name"
            optionValue="id"
          />
        </div>
        <div class="flex flex-column gap-1 col-12 md:col-6">
          <label for="name" class="font-semibold">Name (Optional)</label>
          <InputText
            id="name"
            v-model="newLinkDetail.name"
            class="w-full"
            autocomplete="off"
          />
        </div>
        <div class="flex flex-column gap-1 col-12 md:col-6">
          <label for="isupdategroup" class="font-semibold"
            >Update Specific Group?</label
          >
          <SelectButton
            id="isupdategroup"
            v-model="isUpdateGroup"
            :options="BoolTypes"
            optionLabel="label"
            optionValue="value"
          ></SelectButton>
        </div>
        <div
          v-if="isUpdateGroup && newLinkDetail.partner_id"
          class="flex flex-column gap-1 col-12 md:col-6"
        >
          <label for="selectgroup" class="font-semibold">Group to Update</label>
          <Dropdown
            id="selectgroup"
            v-model="newLinkDetail.group_id"
            :options="
              groups.filter(
                (item) => item.partner_id == newLinkDetail.partner_id
              )
            "
            class="w-full"
            placeholder="Select Group to Update"
            optionLabel="name"
            optionValue="id"
          />
        </div>
      </div>
      <div class="flex justify-end gap-2">
        <Button
          type="button"
          label="Cancel"
          severity="secondary"
          @click="showNewLinkModal = false"
        ></Button>
        <Button type="button" label="Create" @click="addNewLink"></Button>
      </div>
    </Dialog>
  </div>
</template>

<script setup>
import Card from "primevue/card";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import { onBeforeMount, ref } from "vue";
import axios from "axios";
import { API_BASE_URL } from "@/consts";
import { useToast } from "primevue/usetoast";
import { FilterMatchMode } from "primevue/api";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Tag from "primevue/tag";
import { useConfirm } from "primevue/useconfirm";
import Dialog from "primevue/dialog";
import Dropdown from "primevue/dropdown";
import SelectButton from "primevue/selectbutton";

const toast = useToast();
const confirm = useConfirm();

const linksTablesFilters = ref({
  global: { value: null, matchMode: FilterMatchMode.CONTAINS },
});

const pageTitle = "Publisher Onboarding Links";

const adminToken = localStorage.getItem("adminToken");

const links = ref([]);
const partners = ref([]);
const groups = ref([]);
const newLinkDetail = ref({
  partner_id: null,
  group_id: null,
  name: "",
});
const showNewLinkModal = ref(false);
const isUpdateGroup = ref(false);
const tableLoading = ref(false);

const BoolTypes = [
  { label: "No", value: false },
  { label: "Yes", value: true },
];

const fetchLinks = async () => {
  tableLoading.value = true;
  try {
    const result = await axios.get(
      `${API_BASE_URL}/publisher_onboarding_link`,
      { headers: { Authorization: `Bearer ${adminToken}` } }
    );
    if (result.data.data) {
      links.value = result.data.data;
    }
  } catch (error) {
    console.error("Error while fetching the links", error);
    toast.add({
      severity: "error",
      summary: "Error",
      detail: `Error while fetching the links`,
      life: 4000,
    });
  } finally {
    tableLoading.value = false;
  }
};

const fetchPartners = async () => {
  try {
    const result = await axios.get(API_BASE_URL + "/partners", {
      headers: {
        Authorization: `Bearer ${adminToken}`,
      },
    });
    if (result.data) {
      partners.value = result.data;
    }
  } catch (error) {
    console.error("Error fetching partners:", error);
  }
};

const fetchGroups = async () => {
  try {
    const result = await axios.get(`${API_BASE_URL}/seller-groups`, {
      headers: {
        Authorization: `Bearer ${adminToken}`,
      },
    });
    if (result.data.sellerGroups) {
      groups.value = result.data.sellerGroups;
    }
  } catch (error) {
    console.error("Error fetching groups:", error);
  }
};

const openNewLinkModal = () => {
  newLinkDetail.value.name = "";
  newLinkDetail.value.partner_id = null;
  newLinkDetail.value.group_id = null;
  isUpdateGroup.value = false;

  showNewLinkModal.value = true;
};

const copyURLToClipboard = (url) => {
  navigator.clipboard.writeText(url);
};

const confirmLinkDelete = (e, linkId) => {
  confirm.require({
    target: e.currentTarget,
    message: "Do you want to delete this link?",
    icon: "pi pi-info-circle",
    rejectProps: {
      label: "Cancel",
      severity: "secondary",
      outlined: true,
    },
    acceptProps: {
      label: "Delete",
      severity: "danger",
    },
    accept: async () => await deleteLink(linkId),
  });
};

const addNewLink = async () => {
  tableLoading.value = true;
  try {
    if (!newLinkDetail.value.partner_id) return;
    showNewLinkModal.value = false;

    const result = await axios.post(
      `${API_BASE_URL}/publisher_onboarding_link`,
      {
        ...newLinkDetail.value,
        group_id: isUpdateGroup ? newLinkDetail.value.group_id : null,
      },
      { headers: { Authorization: `Bearer ${adminToken}` } }
    );

    if (result.data.success) {
      toast.add({
        severity: "success",
        summary: "Success",
        detail: `Created a new link!`,
        life: 4000,
      });
      await fetchLinks();
    }
  } catch (error) {
    tableLoading.value = false;
    console.error("Error while adding a new link", error);
    toast.add({
      severity: "error",
      summary: "Error",
      detail: `Error while creating a new link`,
      life: 4000,
    });
  }
};

const deleteLink = async (linkId) => {
  tableLoading.value = true;
  try {
    const result = await axios.delete(
      `${API_BASE_URL}/publisher_onboarding_link/${linkId}`,
      { headers: { Authorization: `Bearer ${adminToken}` } }
    );

    if (result.data.success) {
      toast.add({
        severity: "info",
        summary: "Deleted",
        detail: "Link has been deleted",
        life: 4000,
      });
      await fetchLinks();
    }
  } catch (error) {
    tableLoading.value = false;
    console.error("Error while deleting a link", error);
    toast.add({
      severity: "error",
      summary: "Error",
      detail: `Error while deleting a link`,
      life: 4000,
    });
  }
};

onBeforeMount(() => {
  fetchPartners();
  fetchGroups();
  fetchLinks();
});
</script>
