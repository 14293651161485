<template>
  <div>
    <h2 class="mt-0" style="color: var(--text-color)">Dashboard</h2>
    <div class="mb-4 flex flex-column sm:flex-row gap-2 sm:gap-3">
      <Calendar
        v-model="dateRange"
        :showIcon="true"
        :number-of-months="2"
        :selectionMode="'range'"
        @update:modelValue="setDateRange"
      >
      </Calendar>
      <Button @click="fetchOnDateRange">Fetch Data</Button>
    </div>

    <DashboardSection
      v-for="section in sections"
      :key="section.dataMode"
      v-bind="section"
      :data="data[section.dataMode]"
      :loading="loading[section.dataMode]"
      :rows="rows"
      :totalRecords="totalRecords[section.dataMode] * 1"
      @fetch-data="fetchData"
      @download-csv="downloadCsv(section.dataMode, section.dataMode + '.csv')"
    />
  </div>
</template>

<script>
import { ref, reactive, onMounted } from "vue";
import { useRouter } from "vue-router";
import axios from "axios";
import { API_BASE_URL } from "../consts";
import Calendar from "primevue/calendar";
import Button from "primevue/button";
import { useStore } from "vuex";
import { adjustDate } from "../helpers";
import DashboardSection from "./DashboardSection.vue";

export default {
  components: {
    Calendar,
    Button,
    DashboardSection,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const groupAdminData = JSON.parse(localStorage.getItem("groupAdmin"));
    const sellerGroupId = router.currentRoute.value.params.sellerGroupId;

    const createColumns = (
      includeUrl = false,
      includeGeo = false,
      includeSellerId = false
    ) => {
      const columns = [
        { header: "Date", field: "date_trunc" },
        { header: "Player Loads", field: "loads" },
        { header: "Ad Impressions", field: "impressions" },
        { header: "Revenue", field: "revenue" },
      ];

      if (includeUrl) columns.splice(1, 0, { header: "Domain", field: "url" });
      if (includeGeo) columns.splice(1, 0, { header: "Geo", field: "geo" });
      if (includeSellerId)
        columns.splice(1, 0, { header: "Seller ID", field: "seller_id" });

      if (groupAdminData.show_gross_profit) {
        columns.push({ header: "Gross Profit", field: "gross_profit" });
      }

      if (sellerGroupId) {
        columns.push(
          { header: "Cost", field: "cost" },
          { header: "Profit", field: "profit" }
        );
      }

      columns.push({ header: "ECPM", field: "ecpm" });

      return columns;
    };

    const sections = [
      {
        title: "Daily Overview",
        dataMode: "partner_daily",
        columns: createColumns(),
        showChart: true,
        chartLegend: "bottom",
      },
      {
        title: "Per Domain",
        dataMode: "partner_per_domain_daily",
        columns: createColumns(true),
        showChart: true,
        chartLegend: "bottom",
      },
      {
        title: "Per Geo Location",
        dataMode: "partner_per_geo_daily",
        columns: createColumns(false, true),
        showChart: true,
        chartLegend: "bottom",
      },
      {
        title: "Per Seller",
        dataMode: "partner_per_domain_and_seller_daily",
        columns: createColumns(true, false, true),
        showChart: false,
        chartLegend: "bottom",
      },
      {
        title: "Monthly Overview",
        dataMode: "partner_monthly",
        columns: createColumns(),
        showChart: false,
      },
    ];

    const rows = ref(10);
    const totalRecords = reactive({
      partner_daily: 0,
      partner_per_domain_daily: 0,
      partner_per_geo_daily: 0,
      partner_per_seller_daily: 0,
      partner_monthly: 0,
    });
    const data = reactive({
      partner_daily: [],
      partner_per_domain_daily: [],
      partner_per_geo_daily: [],
      partner_per_seller_daily: [],
      partner_monthly: [],
    });

    const csvData = reactive({
      partner_daily: [],
      partner_per_domain_daily: [],
      partner_per_geo_daily: [],
      partner_per_seller_daily: [],
      partner_monthly: [],
    });

    const loading = reactive({
      partner_daily: false,
      partner_per_domain_daily: false,
      partner_per_geo_daily: false,
      partner_per_seller_daily: false,
      partner_monthly: false,
    });

    const dateRange = ref([
      new Date(new Date().setDate(new Date().getDate() - 7)),
      new Date(),
    ]);

    const setDateRange = (value) => {
      dateRange.value = value;
    };

    const fetchData = async ({
      dataMode,
      page = 0,
      rows = 10,
      sortField,
      sortOrder,
      fetchCsvData = false,
    }) => {
      try {
        loading[dataMode] = true;
        const token = sellerGroupId
          ? localStorage.getItem("token")
          : localStorage.getItem("groupAdminToken");

        const startDate = adjustDate(dateRange.value?.[0]);
        const endDate = adjustDate(dateRange.value?.[1]);

        const params = {
          startDate: startDate.toISOString().split("T")[0],
          endDate: endDate.toISOString().split("T")[0],
          dataMode: fetchCsvData ? `${dataMode}_all` : dataMode,
          page,
          rows,
          sortField,
          sortOrder,
        };

        if (sellerGroupId) {
          params.sellerGroupId = sellerGroupId;
        }

        const { data: responseData } = await axios.get(
          `${API_BASE_URL}/group-admin/dashboard`,
          {
            params,
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        if (responseData.success) {
          if (fetchCsvData) {
            csvData[dataMode] = responseData.data[`${dataMode}`] || [];
          } else {
            data[dataMode] = responseData.data[dataMode] || [];
            totalRecords[dataMode] =
              responseData.data[dataMode]?.[0]?.count || 0;
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        if (fetchCsvData) {
          csvData[dataMode] = [];
        } else {
          data[dataMode] = [];
          totalRecords[dataMode] = 0;
        }
      } finally {
        loading[dataMode] = false;
      }
    };

    const fetchCsvData = async (dataMode) => {
      await fetchData({ dataMode, fetchCsvData: true });
    };

    const fetchOnDateRange = () => {
      sections.forEach((section) => {
        fetchData({ dataMode: section.dataMode });
      });
    };

    onMounted(() => {
      sections.forEach((section) => {
        fetchData({ dataMode: section.dataMode });
      });
    });

    const downloadCsv = async (dataMode, filename) => {
      await fetchCsvData(dataMode);
      const fetchedData = csvData[dataMode];
      console.log(fetchedData);
      const csvContent =
        "data:text/csv;charset=utf-8," +
        fetchedData.map((row) => Object.values(row).join(",")).join("\n");
      const encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };

    return {
      sections,
      rows,
      totalRecords,
      data,
      loading,
      dateRange,
      setDateRange,
      fetchData,
      fetchOnDateRange,
      fetchCsvData,
      downloadCsv,
    };
  },
};
</script>
