<template>
  <h2 class="mt-0" style="color: var(--text-color)">Edit Seller</h2>
  <Card class="mb-3">
    <template #title>
      <div class="text-xl">Demand Tags Blacklist/Whitelist</div>
    </template>
    <template #content>
      <div class="grid">
        <div class="flex flex-column gap-1 col-12 md:col-6">
          <label for="tag_bl">Demand Tags Blacklist</label>
          <MultiSelect
            inputId="tag_bl"
            v-model="form.tag_bl"
            @change="handleBlWl('tag_bl')"
            :options="tags"
            option-value="value"
            option-label="label"
            placeholder="Select Item"
            filter
            class="w-full"
          />
        </div>
        <div class="flex flex-column gap-1 col-12 md:col-6">
          <label for="tag_wl">Demand Tags Whitelist</label>
          <MultiSelect
            inputId="tag_wl"
            v-model="form.tag_wl"
            @change="handleBlWl('tag_wl')"
            :options="tags"
            option-value="value"
            option-label="label"
            placeholder="Select Item"
            filter
            class="w-full"
          />
        </div>
        <div class="flex flex-column gap-1 col-12 md:col-6">
          <label for="demo">DEMO</label>
          <SelectButton
            id="demo"
            v-model="form.demo"
            :options="demoOptions"
            option-label="name"
            option-value="value"
          />
        </div>
      </div>
    </template>
    <template #footer>
      <div class="w-full flex justify-content-end gap-2">
        <Button
          class="flex-1 sm:flex-initial"
          outlined
          label="Edit Info"
          @click="goToUpdateSeller()"
        />
        <Button
          class="flex-1 sm:flex-initial"
          outlined
          label="Cancel"
          @click="$emit('close-dialog')"
        />
        <Button
          class="flex-1 sm:flex-initial"
          severity="help"
          label="Configure"
          @click="goToConfigure()"
        />
        <Button
          class="flex-1 sm:flex-initial"
          label="Save"
          @click="submitWlBl()"
        />
      </div>
    </template>
  </Card>
</template>

<script setup>
import Card from "primevue/card";
import MultiSelect from "primevue/multiselect";
import { useStore } from "vuex";
import axios from "axios";
import { API_BASE_URL } from "@/consts";
import { useToast } from "primevue/usetoast";
import { onBeforeMount } from "vue";
import { useRouter } from "vue-router";
import Button from "primevue/button";
import SelectButton from "primevue/selectbutton";
import { ref } from "vue";

const props = defineProps([
  "sellerid",
  "partnerid",
  "tagbl",
  "tagwl",
  "isdemo",
]);
const emit = defineEmits(["update-success"]);

const store = useStore();
const toast = useToast();
const router = useRouter();
const token = localStorage.getItem("adminToken");

const tags = ref([]);

const blWlOpposite = {
  tag_bl: "tag_wl",
  tag_wl: "tag_bl",
};

const demoOptions = ref([
  { name: "Yes", value: true },
  { name: "No", value: false },
]);

const form = ref({
  tag_bl: props.tagbl?.length ? props.tagbl.split(",") : [],
  tag_wl: props.tagwl?.length ? props.tagwl.split(",") : [],
  demo: props.isdemo,
});

console.log(form.value, props.tagbl, props.tagwl);

const handleBlWl = (keyName) => {
  if (form.value[keyName].length) {
    form.value[blWlOpposite[keyName]] = [];
  }
};

const goToConfigure = () => {
  router.push({
    name: "ConfigManager",
    query: {
      sellerid: props.sellerid,
      partnerid: props.partnerid,
    },
  });
};

const goToUpdateSeller = () => {
  router.push({
    name: "AdminUpdateSeller",
    params: { sellerId: props.sellerid },
    query: { partnerId: props.partnerid },
  });
};

const fetchTags = async () => {
  try {
    store.commit("setLoading", true);
    const response = await axios.get(`${API_BASE_URL}/tags`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.data?.tags) {
      tags.value = response.data.tags.map((item) => ({
        value: item.id,
        label: item.name,
      }));
    }
  } catch (error) {
    console.error("Error fetching tags:", error);
  } finally {
    store.commit("setLoading", false);
  }
};

const submitWlBl = async () => {
  try {
    store.commit("setLoading", true);
    const response = await axios.post(
      `${API_BASE_URL}/seller-demand-tag-restriction/${props.sellerid}`,
      form.value,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.data?.success) {
      toast.add({
        severity: "success",
        summary: "Success",
        detail: `Seller demand tag restriction updated successfully!`,
        life: 4000,
      });

      emit("update-success");
      // return to the previous page
      // router.go(-1);
    }
  } catch (error) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: `Something went wrong...`,
      life: 4000,
    });
    console.log(error);
  } finally {
    store.commit("setLoading", false);
  }
};

onBeforeMount(() => {
  fetchTags();
});
</script>
