<template>
  <Badge
    :severity="badgeContent.type"
    class="border-round-3xl select-none"
    :class="
      badgeContent.type == 'danger' || badgeContent.type == 'info'
        ? 'cursor-pointer'
        : ''
    "
    :value="badgeContent.label"
    @click="handleBadgeClick"
  />
  <AdstxtMissingLinesModal
    :visible="showAdstxtVerificationResult"
    :missing-lines="data.adstxt_missing_lines"
    :last-checked="data.adstxt_last_check"
    :ads-txt-per-demand="data.ads_txt_per_demand"
    @close-modal="showAdstxtVerificationResult = false"
  />
</template>
<script setup>
import { ref } from "vue";
import Badge from "primevue/badge";
import AdstxtMissingLinesModal from "./AdstxtMissingLinesModal.vue";
import { computed } from "vue";

const props = defineProps({
  data: Object,
});

const showAdstxtVerificationResult = ref(false);

const handleBadgeClick = () => {
  if (badgeContent.value.type == "danger" || badgeContent.value.type == "info")
    showAdstxtVerificationResult.value = true;
  return;
};

const badgeContent = computed(() => {
  try {
    if (props.data.adstxt_verified) return { type: "success", label: "Passed" };
    if (props.data.adstxt_last_check) {
      const adsTxtPerDemand = JSON.parse(props.data.ads_txt_per_demand);
      const isGreen =
        adsTxtPerDemand &&
        Object.values(adsTxtPerDemand).find((item) => item === "GREEN");
      if (isGreen) return { type: "info", label: "Partial" };
      else return { type: "danger", label: "Failed" };
    }
    return { type: "warning", label: "Pending" };
  } catch (e) {
    console.error("Error parsing ads_txt_per_demand:", e);
    return { type: "warning", label: "Error" };
  }
});
</script>
