<template>
  <div>
    <h2 class="mt-0" style="color: var(--text-color)">Seller Groups</h2>
    <Card>
      <template #header>
        <div
          class="p-3 pb-0 flex flex-column sm:flex-row justify-content-between gap-3"
        >
          <Button
            v-if="!isAdminLoggedIn"
            @click="goToAddGroup()"
            class="justify-content-center"
          >
            Add New Group
          </Button>
          <InputText
            class="w-full sm:w-20rem"
            type="text"
            v-model="groupTablesFilters.global.value"
            placeholder="Global Search"
          />
        </div>
        <div v-if="isAdminLoggedIn" class="px-2">
          <Message :closable="false" severity="warn"
            >{{
              groups.filter((item) => item.status == "inactive").length
            }}
            groups are pending for approval</Message
          >
        </div>
      </template>
      <template #content>
        <DataTable
          :value="groups"
          class="p-datatable-sm"
          :paginator="true"
          :rows="10"
          :filters="groupTablesFilters"
        >
          <Column field="id" header="ID" :sortable="true"></Column>
          <Column field="name" header="Name" :sortable="true"></Column>
          <Column field="email" header="Email" :sortable="true"></Column>
          <Column
            field="display_id"
            header="Display ID"
            :sortable="true"
          ></Column>
          <Column field="status" header="Status" :sortable="true">
            <template #body="slotProps">
              <Badge
                :severity="
                  slotProps.data.status === 'active' ? 'success' : 'danger'
                "
                :value="slotProps.data.status"
                class="capitalize border-round-3xl"
              /> </template
          ></Column>
          <Column>
            <template #body="slotProps">
              <div class="flex gap-2 justify-content-center">
                <Button
                  icon="pi pi-pencil"
                  outlined
                  rounded
                  @click="goToAddGroup(slotProps.data.id)"
                />
                <!-- <Button
                  icon="pi pi-chart-line"
                  outlined
                  rounded
                  severity="help"
                  @click="goToGroupDashboard(slotProps.data.id)"
                /> -->
                <Button
                  v-if="isAdminLoggedIn"
                  icon="pi pi-check"
                  class="p-button-rounded p-button-success p-button-outlined"
                  :disabled="slotProps.data.status == 'active'"
                  aria-label="Approve"
                  @click="
                    ConfirmGroupStatusUpdate(
                      slotProps.data.id,
                      slotProps.data.email,
                      'active'
                    )
                  "
                />
                <Button
                  icon="pi pi-trash"
                  outlined
                  rounded
                  severity="danger"
                  @click="ConfirmGroupDelete(slotProps.data.id)"
                />
              </div>
            </template>
          </Column>
        </DataTable>
      </template>
    </Card>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { useStore } from "vuex";
import { FilterMatchMode } from "primevue/api";
import InputText from "primevue/inputtext";
import axios from "axios";
import { API_BASE_URL } from "../consts";
import { useRouter } from "vue-router";
import Button from "primevue/button";
import Card from "primevue/card";
import { useConfirm } from "primevue/useconfirm";
import { useToast } from "primevue/usetoast";
import Badge from "primevue/badge";
import Message from "primevue/message";

export default {
  components: {
    InputText,
    Button,
    Card,
    Badge,
    Message,
  },
  setup() {
    const groups = ref([]);
    const store = useStore();
    const router = useRouter();
    const toast = useToast();
    const confirm = useConfirm();
    const groupTablesFilters = ref({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    const isAdminLoggedIn = store.getters.isAdminLoggedIn;

    const selectedGroup = ref({});
    const token =
      localStorage.getItem("adminToken") || localStorage.getItem("token");

    const goToGroupDashboard = (groupId) => {
      router.push({
        path: "/group-dashboard/" + groupId,
      });
    };

    const goToAddGroup = (groupId) => {
      if (groupId) {
        router.push({
          name: isAdminLoggedIn
            ? "AdminUpdateSellerGroup"
            : "UpdateSellerGroup",
          params: {
            groupId,
          },
        });
      } else {
        router.push({
          name: "AddSellerGroup",
        });
      }
    };

    const fetchGroups = async () => {
      try {
        store.commit("setLoading", true);
        const response = await axios.get(`${API_BASE_URL}/seller-groups`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.data?.sellerGroups) {
          groups.value = response.data.sellerGroups;
        }
      } catch (error) {
        console.error("Error fetching groups:", error);
      } finally {
        store.commit("setLoading", false);
      }
    };

    const deleteGroup = async (groupId) => {
      try {
        store.commit("setLoading", true);
        const response = await axios.delete(
          `${API_BASE_URL}/seller-group/${groupId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.data.success) {
          // Remove the group from the local state
          groups.value = groups.value.filter((group) => group.id !== groupId);
        }
      } catch (error) {
        console.error("Error deleting group:", error);
      } finally {
        store.commit("setLoading", false);
      }
    };

    const updateGroupStatus = async (groupId, email, status) => {
      try {
        const response = await axios.put(
          `${API_BASE_URL}/admin/seller-groups/${groupId}/status`,
          { status, email },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status == 200) {
          // update the status in the local data
          const group = groups.value.find((s) => s.id === groupId);
          if (group) {
            group.status = status;
            toast.add({
              severity: "info",
              summary: `Group ${status}`,
              detail: `Group has been ${status} successfully.`,
              life: 4000,
            });
          }
        }
      } catch (error) {
        console.error(`Error updating group status to ${status}: `, error);
      }
    };

    const ConfirmGroupDelete = (groupId) => {
      confirm.require({
        message: "Do you want to delete this group?",
        header: "Delete Confirmation",
        icon: "pi pi-info-circle",
        acceptClass: "p-button-danger",
        accept: () => {
          deleteGroup(groupId);
        },
      });
    };

    const ConfirmGroupStatusUpdate = (groupId, email, status) => {
      confirm.require({
        message: `Do you want to ${status} this group?`,
        header: "Update Confirmation",
        icon: "pi pi-info-circle",
        accept: () => {
          updateGroupStatus(groupId, email, status);
        },
      });
    };

    onMounted(fetchGroups);

    return {
      groups,
      deleteGroup,
      goToAddGroup,
      selectedGroup,
      groupTablesFilters,
      fetchGroups,
      goToGroupDashboard,
      ConfirmGroupDelete,
      isAdminLoggedIn,
      ConfirmGroupStatusUpdate,
    };
  },
};
</script>
