<template>
  <div>
    <h2 class="mt-0" style="color: var(--text-color)">Ads Partner Tags</h2>
    <Card>
      <template #header>
        <div
          class="p-3 pb-0 flex flex-column sm:flex-row justify-content-between gap-3"
        >
          <Button @click="goToAddTag()" class="justify-content-center">
            Add New Tag
          </Button>
          <Button @click="goToTagsListChecker()" class="justify-content-center">
            Check Tag List For Seller
          </Button>
          <Button @click="goToAdstxtChecker()" class="justify-content-center"
            >Adstxt Report</Button
          >
          Check Tag List For Buyer
          <InputText
            class="w-full sm:w-20rem"
            type="text"
            v-model="tagTablesFilters.global.value"
            placeholder="Global Search"
          />
        </div>
      </template>
      <template #content>
        <DataTable
          :value="tags"
          class="p-datatable-sm"
          :paginator="true"
          :rows="10"
          :filters="tagTablesFilters"
        >
          <Column field="id" header="ID" :sortable="true"></Column>
          <Column field="name" header="Name" :sortable="true">
            <template #body="slotProps">
              <div class="line-clamp-2">
                {{ slotProps.data.name }}
              </div>
            </template>
          </Column>
          <Column field="cpm" header="CPM" :sortable="true"></Column>
          <Column class="max-w-20rem" field="url" header="URL" :sortable="true">
            <template #body="slotProps">
              <div class="line-clamp-2">
                {{ slotProps.data.url }}
              </div>
            </template>
          </Column>
          <Column field="tag_group" header="Tag group" :sortable="true">
            <template #body="slotProps">
              {{
                tagGroups.find((item) => item.id === slotProps.data.tag_group)
                  ?.name
              }}
            </template>
          </Column>
          <Column field="active" header="Status" :sortable="true">
            <template #body="slotProps">
              <Badge
                :severity="slotProps.data.active ? 'success' : 'warning'"
                class="capitalize border-round-3xl"
                :value="slotProps.data.active ? 'Active' : 'Inactive'"
              />
            </template>
          </Column>
          <Column>
            <template #body="slotProps">
              <div class="flex gap-2 justify-content-center">
                <Button
                  icon="pi pi-pencil"
                  outlined
                  rounded
                  @click="goToAddTag(slotProps.data.id)"
                />
                <Button
                  icon="pi pi-trash"
                  outlined
                  rounded
                  severity="danger"
                  @click="ConfirmTagDelete(slotProps.data.id)"
                />
              </div>
            </template>
          </Column>
        </DataTable>
      </template>
    </Card>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useStore } from "vuex";
import { FilterMatchMode } from "primevue/api";
import InputText from "primevue/inputtext";
import axios from "axios";
import { API_BASE_URL } from "../consts";
import { useRouter } from "vue-router";
import Button from "primevue/button";
import Badge from "primevue/badge";
import Card from "primevue/card";
import { useConfirm } from "primevue/useconfirm";

const tags = ref([]);
const tagGroups = ref([]);
const store = useStore();
const router = useRouter();
const confirm = useConfirm();
const tagTablesFilters = ref({
  global: { value: null, matchMode: FilterMatchMode.CONTAINS },
});

const goToAddTag = (tagId) => {
  if (tagId) {
    router.push({
      name: "UpdateTag",
      params: {
        tagId,
      },
    });
  } else {
    router.push({
      name: "AddTag",
    });
  }
};

const goToTagsListChecker = () => {
  router.push({
    name: "TagsListChecker",
  });
};

const goToAdstxtChecker = () => {
  router.push({
    name: "DemandAdstxtReport",
  });
};

const fetchTagGroups = async () => {
  try {
    store.commit("setLoading", true);

    const token = localStorage.getItem("adminToken");
    const response = await axios.get(API_BASE_URL + "/tag-group", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.data) {
      tagGroups.value = response.data;
    }
  } catch (error) {
    console.error("Error fetching tag groups:", error);
  } finally {
    store.commit("setLoading", false);
  }
};

const fetchTags = async () => {
  try {
    store.commit("setLoading", true);
    const token = localStorage.getItem("adminToken");
    const response = await axios.get(`${API_BASE_URL}/tags`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.data?.tags) {
      tags.value = response.data.tags;
    }
  } catch (error) {
    console.error("Error fetching tags:", error);
  } finally {
    store.commit("setLoading", false);
  }
};

const deleteTag = async (tagId) => {
  try {
    store.commit("setLoading", true);
    const token = localStorage.getItem("adminToken");
    const response = await axios.delete(`${API_BASE_URL}/tag/${tagId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.data.success) {
      // Remove the tag from the local state
      tags.value = tags.value.filter((tag) => tag.id !== tagId);
    }
  } catch (error) {
    console.error("Error deleting tag:", error);
  } finally {
    store.commit("setLoading", false);
  }
};

const ConfirmTagDelete = (tagId) => {
  confirm.require({
    message: "Do you want to delete this tag?",
    header: "Delete Confirmation",
    icon: "pi pi-info-circle",
    acceptClass: "p-button-danger",
    accept: () => {
      deleteTag(tagId);
    },
  });
};

onMounted(() => {
  fetchTagGroups();
  fetchTags();
});
</script>

<style scoped>
.line-clamp-2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  white-space: pre-wrap;
}
</style>
