<template>
  <Dialog
    :visible="visible"
    :modal="true"
    :closable="false"
    :header="`Generate Tag for ${selectedSeller.name}`"
    :style="{ width: '45rem', maxWidth: '92vw' }"
  >
    <div class="grid">
      <div class="col-12 sm:col-6">
        <div class="mb-1">Player Layout</div>
        <Dropdown
          v-model="playerOptions.layout"
          :options="layoutOptions"
          optionLabel="label"
          option-value="value"
          placeholder="Select a Layout"
          class="w-full"
        />
      </div>
      <div class="col-12 sm:col-6">
        <div class="mb-1">Player Width</div>
        <InputNumber
          inputId="integeronly"
          :min="300"
          :max="1200"
          placeholder="Player Width"
          v-model="playerOptions.width"
          class="w-full"
        />
      </div>
    </div>
    <div class="grid">
      <div class="col-12 sm:col-6">
        <div>
          <div class="mr-2">
            <span>Hide Slogan</span>
          </div>
          <div>
            <InputSwitch v-model="playerOptions.hide_slogan" />
          </div>
        </div>

        <div>
          <div class="mr-2">Hide FAB</div>
          <InputSwitch v-model="playerOptions.hide_fab" />
        </div>
        <div>
          <div class="mr-2">FAB on left side</div>
          <InputSwitch v-model="playerOptions.fab_left" />
        </div>
      </div>
      <div class="col-12 sm:col-6">
        <div>
          <div class="mr-2">Open in new tab when play</div>
          <InputSwitch v-model="playerOptions.go_external_on_play" />
        </div>
        <div>
          <div class="mr-2">Disable playlist shuffling</div>
          <InputSwitch v-model="playerOptions.disable_shuffling" />
        </div>
      </div>
    </div>
    <div>
      <div class="mb-1">
        Copy & paste this code to where you want to embed the player.
      </div>
      <div
        class="bg-primary-reverse overflow-x-hidden border-1 border-round-md p-3 mb-2 relative"
      >
        <Button
          @click="codeToClipboard('componentcode')"
          icon="pi pi-copy"
          text
          aria-label="Copy"
          v-tooltip.bottom="'Copy'"
          class="absolute right-0 top-0 embed-code-copy-btn"
        />
        <code ref="componentcode">{{ componentCode }}</code>
      </div>
      <div class="mb-1">And put this snippet before your body tag ends.</div>
      <div
        class="bg-primary-reverse overflow-x-hidden border-1 border-round-md p-3 relative"
      >
        <Button
          @click="codeToClipboard('componentsrc')"
          icon="pi pi-copy"
          text
          aria-label="Copy"
          v-tooltip.bottom="'Copy'"
          class="absolute right-0 top-0 embed-code-copy-btn"
        />
        <code ref="componentsrc">{{ componentSrc }}</code>
      </div>
    </div>
    <div class="mt-3 mb-1 font-medium">ads.txt contents</div>
    <div class="grid">
      <div class="col-12 sm:col-6">
        <div class="mb-1">Priority</div>
        <MultiSelect
          v-model="adsTxtOptions.priority"
          :options="priorityOptions"
          optionLabel="label"
          option-value="value"
          placeholder="Select Priorities"
          class="w-full"
        />
      </div>
      <div class="col-12 sm:col-6">
        <div class="mb-1">Type</div>
        <MultiSelect
          v-model="adsTxtOptions.content_type"
          :options="contentTypeOptions"
          optionLabel="label"
          option-value="value"
          placeholder="Select Types"
          class="w-full"
        />
      </div>
    </div>
    <div
      class="surface-50 surface-border text-color-secondary border-1 overflow-x-hidden border-round-md p-3 mb-2 relative"
    >
      <Button
        @click="codeToClipboard('adstxt')"
        icon="pi pi-copy"
        text
        aria-label="Copy"
        security="secondary"
        v-tooltip.bottom="'Copy'"
        class="absolute right-0 top-0 embed-code-copy-btn"
      />

      <pre ref="adstxt" class="my-0">{{ adsTxtCode }}</pre>
    </div>
    <div class="my-1">
      <p class="my-0">This content should be added to:</p>
      <p class="text-blue-500 my-0">/ads.txt</p>
    </div>
    <template #footer>
      <Button
        v-if="partner.domain && partner.id"
        label="Save as file"
        @click="saveEmbedAsFile"
      />
      <Button label="Close" class="mt-3" outlined @click="closeDialog" />
    </template>
  </Dialog>
</template>

<script>
import InputNumber from "primevue/inputnumber";
import Dropdown from "primevue/dropdown";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import InputSwitch from "primevue/inputswitch";
import { adsTxtFixed } from "@/consts";
import { downloadTextFile, generateEmbedGuide } from "@/helpers";
import MultiSelect from "primevue/multiselect";

export default {
  components: {
    Dialog,
    InputNumber,
    Dropdown,
    Button,
    InputSwitch,
    MultiSelect,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    selectedSeller: {
      type: Object,
      default: () => ({}),
    },
    adsTxtContent: {
      type: Array,
      default: [],
    },
    partner: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      playerOptions: {
        layout: this.partner?.default_widget_layout || "0",
        width: 0,
        hide_slogan: false,
        hide_fab: false,
        fab_left: false,
        disable_shuffling: false,
        go_external_on_play: false,
      },
      adsTxtOptions: {
        priority: [1, 2, 3],
        content_type: ["audio", "display", "video"],
      },
      layoutOptions: [
        { label: "Low Visible", value: "0" },
        { label: "High Visible", value: "1" },
      ],
      yesNoOptions: [
        { label: "No", value: false },
        { label: "Yes", value: true },
      ],
      priorityOptions: [
        { label: "Low", value: 3 },
        { label: "High", value: 2 },
        { label: "Critical", value: 1 },
      ],
      contentTypeOptions: [
        { label: "Audio", value: "audio" },
        { label: "Display", value: "display" },
        { label: "Video", value: "video" },
      ],
    };
  },
  computed: {
    componentCode() {
      const tagName = "jamx";
      const versionAttr = "layout";
      const versionValue = this.playerOptions.layout;
      return `<${tagName}-player seller="${this.partner.id}" subseller="${
        this.selectedSeller.id
      }" domain="${
        this.selectedSeller.domain
      }" ${versionAttr}="${versionValue}" width="${this.playerOptions.width}"${
        this.playerOptions.hide_slogan ? " hide_slogan" : ""
      }${this.playerOptions.hide_fab ? " hide_fab" : ""}${
        this.playerOptions.fab_left ? " fab_left" : ""
      }${this.playerOptions.disable_shuffling ? " disable_shuffling" : ""}${
        this.playerOptions.go_external_on_play ? " go_external_on_play" : ""
      }></${tagName}-player>`;
    },
    componentSrc() {
      const domain = "wgt.podcastswaves.com";
      return `<script src="https://${domain}/jamx.player.js"><\/script>`;
    },
    adsTxtCode() {
      return `${adsTxtFixed(
        this.selectedSeller.group_id,
        this.selectedSeller.group_display_id
      )}\n${this.adsTxtContent
        .filter(
          (item) =>
            this.adsTxtOptions.content_type.includes(item.type) &&
            this.adsTxtOptions.priority.includes(item.priority)
        )
        .map((row) => row.row_string)
        .join("\n")}`;
    },
  },
  watch: {
    visible(newValue) {
      if (newValue) {
        console.log("The dialog is now visible.");
      } else {
        console.log("The dialog is now hidden.");
        this.adsTxtOptions.content_type = ["audio", "display", "video"];
        this.adsTxtOptions.priority = [1, 2, 3];
      }
    },
  },
  methods: {
    closeDialog() {
      this.$emit("close-dialog");
    },
    codeToClipboard(refName) {
      let clipboardData = this.$refs[refName].innerText;
      navigator.clipboard.writeText(clipboardData);
    },
    saveEmbedAsFile() {
      let embedData = generateEmbedGuide(
        this.componentCode,
        this.componentSrc,
        this.adsTxtCode
      );
      downloadTextFile(embedData, "how_to_embed.txt");
    },
  },
};
</script>

<style>
.embed-mode-select > * {
  width: 50%;
}

.embed-code-copy-btn:focus {
  box-shadow: none !important;
}
</style>
