<template>
  <div>
    <h2 class="mt-0" style="color: var(--text-color)">AdsTxt Partner Report</h2>
    <Card>
      <template #header>
        <div
          class="p-3 pb-0 flex flex-column sm:flex-row justify-content-between gap-3"
        >
          <Dropdown
            input-id="partners"
            filter
            :options="partners"
            v-model="selectedPartner"
            optionLabel="partner_name"
            optionValue="partner_name"
            placeholder="Select Partner"
            class="w-full sm:w-20rem"
          />
          <Button
            label="Generate Report"
            @click="generateReport"
            :loading="loading"
          />
        </div>
      </template>
      <template #content>
        <DataTable :value="report" class="p-datatable-sm" :loading="loading">
          <Column
            field="publisherName"
            header="Publisher Name"
            :sortable="true"
          ></Column>
          <Column field="loads" header="Loads" :sortable="true"></Column>
          <Column field="adsTxtStatus" header="AdsTxt Status" :sortable="true">
            <template #body="slotProps">
              <span
                :class="
                  slotProps.data.adsTxtStatus === 'GREEN'
                    ? 'text-green-500'
                    : 'text-red-500'
                "
              >
                {{ slotProps.data.adsTxtStatus }}
              </span>
            </template>
          </Column>
          <Column header="Missing Lines">
            <template #body="slotProps">
              <ul v-if="slotProps.data.adsTxtStatus === 'RED'">
                <li v-for="line in slotProps.data.missingLines" :key="line">
                  {{ line }}
                </li>
              </ul>
            </template>
          </Column>
          <Column header="is set on tag">
            <template #body="slotProps">
              {{ slotProps.data.publisherExistsInWhitelist }}
            </template>
          </Column>
          <Column header="Actions">
            <template #body="slotProps">
              <Button
                label="Add"
                class="p-button-sm p-button-success mr-2"
                @click="addPublisher(slotProps.data.publisherId)"
              />
              <Button
                label="Remove"
                class="p-button-sm p-button-danger"
                @click="removePublisher(slotProps.data.publisherId)"
              />
            </template>
          </Column>
        </DataTable>
      </template>
    </Card>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from "vue";
import axios from "axios";
import Button from "primevue/button";
import Card from "primevue/card";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Dropdown from "primevue/dropdown";
import { API_BASE_URL } from "../consts";

const adsTxtRows = ref([]);
const selectedPartner = ref(null);
const report = ref([]);
const loading = ref(false);

const partners = computed(() => {
  console.log(adsTxtRows);
  const uniquePartners = new Set(
    adsTxtRows.value.map((row) => row.partner_name)
  );
  return Array.from(uniquePartners).map((partner_name) => ({ partner_name }));
});

const fetchAdsTxtRows = async () => {
  try {
    const token = localStorage.getItem("adminToken");
    const { data } = await axios.get(`${API_BASE_URL}/adstxt-rows`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    adsTxtRows.value = data.result;
  } catch (error) {
    console.error("Error fetching AdsTxt rows:", error);
  }
};

const generateReport = async () => {
  if (!selectedPartner.value) return;

  loading.value = true;
  try {
    const token = localStorage.getItem("adminToken");
    const { data } = await axios.get(`${API_BASE_URL}/adstxt-partner-report`, {
      params: { partnerName: selectedPartner.value },
      headers: { Authorization: `Bearer ${token}` },
    });
    report.value = data.report;
  } catch (error) {
    console.error("Error generating report:", error);
  } finally {
    loading.value = false;
  }
};

const addPublisher = (publisherId) => {
  // Implement add functionality
  console.log(`Add publisher ${publisherId} to whitelist`);
};

const removePublisher = (publisherId) => {
  // Implement remove functionality
  console.log(`Remove publisher ${publisherId} from whitelist`);
};

onMounted(() => {
  fetchAdsTxtRows();
});
</script>
