<template>
  <div>
    <h2 class="mt-0" style="color: var(--text-color)">Admin Dashboard</h2>
    <div class="mb-4 grid align-items-end" style="color: var(--text-color)">
      <div class="col-12 sm:col-6 lg:col-3 flex flex-column gap-1">
        <label for="calendar">Select Date Range</label>
        <Calendar
          input-id="calendar"
          v-model="dateRange"
          :showIcon="true"
          :number-of-months="2"
          :selectionMode="'range'"
          class="w-full"
          style="color: var(--text-color) !important"
          @update:modelValue="setDateRange"
        >
        </Calendar>
      </div>
      <div class="col-12 sm:col-6 lg:col-3 flex flex-column gap-1">
        <label for="partners-list">Select Partners</label>
        <MultiSelect
          input-id="partners-list"
          display="chip"
          :options="memberList.partners"
          v-model="memberFilter.partners"
          option-label="name"
          option-value="id"
          placeholder="Select Partners"
          class="w-full"
        />
      </div>
      <div class="col-12 sm:col-6 lg:col-3 flex flex-column gap-1">
        <label for="groups-list">Select Groups</label>
        <MultiSelect
          input-id="groups-list"
          filter
          display="chip"
          :options="memberList.groups"
          v-model="memberFilter.groups"
          option-label="name"
          option-value="id"
          placeholder="Select Groups"
          class="w-full"
        />
      </div>
      <div class="col-12 sm:col-6 lg:col-3 flex flex-column gap-1">
        <label for="sellers-list">Select Sellers</label>
        <MultiSelect
          input-id="sellers-list"
          filter
          display="chip"
          :options="memberList.sellers"
          v-model="memberFilter.sellers"
          :optionLabel="(option) => `[${option.id}] ${option.name}`"
          option-value="id"
          placeholder="Select Sellers"
          class="w-full"
        />
      </div>
      <div class="col-12 sm:col-6 lg:col-3 flex flex-column gap-1">
        <label for="tags-list">Select Tags</label>
        <MultiSelect
          input-id="tags-list"
          filter
          display="chip"
          :options="tags"
          v-model="memberFilter.tags"
          optionLabel="name"
          option-value="id"
          placeholder="Select Tags"
          class="w-full"
        />
      </div>
      <div class="col-12 sm:col-6 lg:col-3 flex flex-column gap-1">
        <label for="taggroups-list">Select Tag Partners</label>
        <MultiSelect
          input-id="taggroups-list"
          filter
          display="chip"
          :options="tagGroups"
          v-model="memberFilter.tag_partners"
          optionLabel="name"
          option-value="name"
          placeholder="Select Tag Partners"
          class="w-full"
        />
      </div>
      <div class="col-12 sm:col-6 lg:col-3">
        <Button @click="fetchOnDateRange" class="justify-content-center w-full"
          >Fetch Data</Button
        >
      </div>
    </div>
    <Card class="mb-4">
      <template #title>
        <div class="text-xl">Daily</div>
      </template>
      <template #content>
        <div class="relative">
          <line-chart
            :data="filteredChartData"
            :options="chartOptions"
          ></line-chart>

          <LoaderSm v-if="chartLoading.admin_daily" />
        </div>

        <!-- Dataset toggles -->
        <div class="mb-4">
          <label v-for="(dataset, index) in chartData" :key="index">
            <input type="checkbox" v-model="visibleDatasets" :value="index" />
            {{ dataset.name }}
          </label>
        </div>

        <DataTable
          :value="dailyData"
          :defaultSortOrder="-1"
          class="p-datatable-striped p-datatable-sm"
          :paginator="true"
          :rows="rows"
          :totalRecords="totalRecords.daily"
          lazy
          @page="
            (e) => {
              fetchData('admin_daily', e);
              console.log('TABLE ACTION: ', e);
            }
          "
          @sort="(e) => fetchData('admin_daily', e)"
          :loading="tableLoading.admin_daily"
        >
          <Column
            v-for="col in columns"
            :key="col.field"
            :field="col.field"
            :header="col.header"
            sortable
          ></Column>
          <template #loadingicon>
            <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
          </template>
          <template #paginatorstart>
            <Button
              type="button"
              icon="pi pi-refresh"
              @click="fetchData('admin_daily', { page: 0, rows })"
              text
            />
          </template>
          <template #paginatorend>
            <download-csv :data="csvData.adminDaily" class="download-csv">
              <Button type="button" icon="pi pi-download" text />
            </download-csv>
          </template>
        </DataTable>
      </template>
    </Card>
    <Card class="mb-4">
      <template #title>
        <div class="text-xl">Monthly</div>
      </template>
      <template #content>
        <DataTable
          :value="monthlyData"
          :defaultSortOrder="-1"
          class="p-datatable-striped p-datatable-sm"
          :paginator="true"
          :rows="rows"
          :totalRecords="totalRecords.monthly"
          lazy
          @page="(e) => fetchData('admin_monthly', e)"
          @sort="(e) => fetchData('admin_monthly', e)"
          :loading="tableLoading.admin_monthly"
        >
          <Column
            v-for="col in columns"
            :key="col.field"
            :field="col.field"
            :header="col.header"
            sortable
          ></Column>
          <template #loadingicon>
            <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
          </template>
          <template #paginatorstart>
            <Button
              type="button"
              icon="pi pi-refresh"
              @click="fetchData('admin_monthly', { page: 0, rows })"
              text
            />
          </template>
          <template #paginatorend>
            <download-csv :data="csvData.adminMonthly" class="download-csv">
              <Button type="button" icon="pi pi-download" text />
            </download-csv>
          </template>
        </DataTable>
      </template>
    </Card>
    <Card class="mb-4">
      <template #title>
        <div class="text-xl">Per Partner</div>
      </template>
      <template #content>
        <div class="relative">
          <line-chart
            class="pb-4"
            :data="chartDataPerPartner"
            :options="{
              responsive: true,
              maintainAspectRatio: false,
              tooltips: {
                callbacks: {
                  label: function (tooltipItem) {
                    const value = tooltipItem.yLabel;
                    return `$${value
                      .toFixed(2)
                      .replace(/\d(?=(\d{3})+\.)/g, '$&,')}`;
                  },
                },
              },
            }"
            legend="bottom"
          ></line-chart>
          <LoaderSm v-if="chartLoading.admin_per_partner_daily" />
        </div>
        <DataTable
          :value="perPartnerDaily"
          :defaultSortOrder="-1"
          class="p-datatable-striped p-datatable-sm"
          :paginator="true"
          :rows="rows"
          :totalRecords="totalRecords.partnerly"
          lazy
          @page="(e) => fetchData('admin_per_partner_daily', e)"
          @sort="(e) => fetchData('admin_per_partner_daily', e)"
          :loading="tableLoading.admin_per_partner_daily"
        >
          <Column
            v-for="col in columnsPerPartner"
            :key="col.field"
            :field="col.field"
            :header="col.header"
            sortable
          >
          </Column>
          <template #loadingicon>
            <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
          </template>
          <template #paginatorstart>
            <Button
              type="button"
              icon="pi pi-refresh"
              @click="fetchData('admin_per_partner_daily', { page: 0, rows })"
              text
            />
          </template>
          <template #paginatorend>
            <download-csv
              :data="csvData.adminPerPartnerDaily"
              class="download-csv"
            >
              <Button type="button" icon="pi pi-download" text />
            </download-csv>
          </template>
        </DataTable>
      </template>
    </Card>
    <Card class="mb-4">
      <template #title>
        <div class="text-xl">Per Partner Monthly</div>
      </template>
      <template #content>
        <DataTable
          :value="perPartnerMonthly"
          :defaultSortOrder="-1"
          class="p-datatable-striped p-datatable-sm"
          :paginator="true"
          :rows="rows"
          :totalRecords="totalRecords.partnerMonthly"
          lazy
          @page="(e) => fetchData('admin_per_partner_monthly', e)"
          @sort="(e) => fetchData('admin_per_partner_monthly', e)"
          :loading="tableLoading.admin_per_partner_monthly"
        >
          <Column
            v-for="col in columnsPerPartner"
            :key="col.field"
            :field="col.field"
            :header="col.header"
            sortable
          >
          </Column>
          <template #loadingicon>
            <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
          </template>
          <template #paginatorstart>
            <Button
              type="button"
              icon="pi pi-refresh"
              @click="fetchData('admin_per_partner_monthly', { page: 0, rows })"
              text
            />
          </template>
          <template #paginatorend>
            <download-csv
              :data="csvData.adminPerPartnerMonthly"
              class="download-csv"
            >
              <Button type="button" icon="pi pi-download" text />
            </download-csv>
          </template>
        </DataTable>
      </template>
    </Card>
    <Card class="mb-4">
      <template #title>
        <div class="text-xl">Per Geo Location</div>
      </template>
      <template #content>
        <div class="relative">
          <line-chart
            class="pb-4"
            :data="chartDataPerGeo"
            :options="{ responsive: true, maintainAspectRatio: false }"
            legend="{display: false}"
          ></line-chart>
          <LoaderSm v-if="chartLoading.admin_per_geo_daily" />
        </div>
        <DataTable
          :value="perGeoData"
          :defaultSortOrder="-1"
          class="p-datatable-striped p-datatable-sm"
          :paginator="true"
          :rows="rows"
          :totalRecords="totalRecords.geoly"
          lazy
          @page="(e) => fetchData('admin_per_geo_daily', e)"
          @sort="(e) => fetchData('admin_per_geo_daily', e)"
          :loading="tableLoading.admin_per_geo_daily"
        >
          <Column
            v-for="col in columnsPerGeo"
            :key="col.field"
            :field="col.field"
            :header="col.header"
            sortable
          >
          </Column>
          <template #loadingicon>
            <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
          </template>
          <template #paginatorstart>
            <Button
              type="button"
              icon="pi pi-refresh"
              @click="fetchData('admin_per_geo_daily', { page: 0, rows })"
              text
            />
          </template>
          <template #paginatorend>
            <download-csv :data="csvData.adminPerGeoDaily" class="download-csv">
              <Button type="button" icon="pi pi-download" text />
            </download-csv>
          </template>
        </DataTable>
      </template>
    </Card>
    <Card class="mb-4">
      <template #title>
        <div class="text-xl">Per Domain Daily</div>
      </template>
      <template #content>
        <DataTable
          :value="perDomainDaily"
          :defaultSortOrder="-1"
          class="p-datatable-striped p-datatable-sm"
          :paginator="true"
          :rows="rows"
          :totalRecords="totalRecords.domainDaily"
          lazy
          @page="(e) => fetchData('admin_per_domain_daily', e)"
          @sort="(e) => fetchData('admin_per_domain_daily', e)"
          :loading="tableLoading.admin_per_domain_daily"
        >
          <Column
            v-for="col in columnsPerDomainDaily"
            :key="col.field"
            :field="col.field"
            :header="col.header"
            sortable
          >
          </Column>
          <template #loadingicon>
            <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
          </template>
          <template #paginatorstart>
            <Button
              type="button"
              icon="pi pi-refresh"
              @click="fetchData('admin_per_domain_daily', { page: 0, rows })"
              text
            />
          </template>
          <template #paginatorend>
            <download-csv
              :data="csvData.adminPerDomainDaily"
              class="download-csv"
            >
              <Button type="button" icon="pi pi-download" text />
            </download-csv>
          </template>
        </DataTable>
      </template>
    </Card>
    <Card class="mb-4">
      <template #title>
        <div class="text-xl">Per Group Monthly</div>
      </template>
      <template #content>
        <DataTable
          :value="perGroupMonthly"
          :defaultSortOrder="-1"
          class="p-datatable-striped p-datatable-sm"
          :paginator="true"
          :rows="rows"
          :totalRecords="totalRecords.groupMonthly"
          lazy
          @page="(e) => fetchData('admin_per_group_monthly', e)"
          @sort="(e) => fetchData('admin_per_group_monthly', e)"
          :loading="tableLoading.admin_per_group_monthly"
        >
          <Column
            v-for="col in columnsPerGroupMonthly"
            :key="col.field"
            :field="col.field"
            :header="col.header"
            sortable
          >
          </Column>
          <template #loadingicon>
            <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
          </template>
          <template #paginatorstart>
            <Button
              type="button"
              icon="pi pi-refresh"
              @click="fetchData('admin_per_group_monthly', { page: 0, rows })"
              text
            />
          </template>
          <template #paginatorend>
            <download-csv
              :data="csvData.adminPerGroupMonthly"
              class="download-csv"
            >
              <Button type="button" icon="pi pi-download" text />
            </download-csv>
          </template>
        </DataTable>
      </template>
    </Card>
    <Card class="mb-4">
      <template #title>
        <div class="text-xl">Per Domain Monthly</div>
      </template>
      <template #content>
        <DataTable
          :value="perDomainMonthly"
          :defaultSortOrder="-1"
          class="p-datatable-striped p-datatable-sm"
          :paginator="true"
          :rows="rows"
          :totalRecords="totalRecords.domainMonthly"
          lazy
          @page="(e) => fetchData('admin_per_domain_monthly', e)"
          @sort="(e) => fetchData('admin_per_domain_monthly', e)"
          :loading="tableLoading.admin_per_domain_monthly"
        >
          <Column
            v-for="col in columnsPerDomainDaily"
            :key="col.field"
            :field="col.field"
            :header="col.header"
            sortable
          >
          </Column>
          <template #loadingicon>
            <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
          </template>
          <template #paginatorstart>
            <Button
              type="button"
              icon="pi pi-refresh"
              @click="fetchData('admin_per_domain_monthly', { page: 0, rows })"
              text
            />
          </template>
          <template #paginatorend>
            <download-csv
              :data="csvData.adminPerDomainMonthly"
              class="download-csv"
            >
              <Button type="button" icon="pi pi-download" text />
            </download-csv>
          </template>
        </DataTable>
      </template>
    </Card>
    <Card class="mb-4">
      <template #title>
        <div class="text-xl">Per Geo Monthly</div>
      </template>
      <template #content>
        <DataTable
          :value="perGeoMonthly"
          :defaultSortOrder="-1"
          class="p-datatable-striped p-datatable-sm"
          :paginator="true"
          :rows="rows"
          :totalRecords="totalRecords.geoMonthly"
          lazy
          @page="(e) => fetchData('admin_per_geo_monthly', e)"
          @sort="(e) => fetchData('admin_per_geo_monthly', e)"
          :loading="tableLoading.admin_per_geo_monthly"
        >
          <Column
            v-for="col in columnsPerGeo"
            :key="col.field"
            :field="col.field"
            :header="col.header"
            sortable
          >
          </Column>
          <template #loadingicon>
            <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
          </template>
          <template #paginatorstart>
            <Button
              type="button"
              icon="pi pi-refresh"
              @click="fetchData('admin_per_geo_monthly', { page: 0, rows })"
              text
            />
          </template>
          <template #paginatorend>
            <download-csv
              :data="csvData.adminPerGeoMonthly"
              class="download-csv"
            >
              <Button type="button" icon="pi pi-download" text />
            </download-csv>
          </template>
        </DataTable>
      </template>
    </Card>
    <Card class="mb-4">
      <template #title>
        <div class="text-xl">Per Tag Daily</div>
      </template>
      <template #content>
        <DataTable
          :value="perTagDaily"
          :defaultSortOrder="-1"
          class="p-datatable-striped p-datatable-sm"
          :paginator="true"
          :rows="rows"
          :totalRecords="totalRecords.tagDaily"
          lazy
          @page="(e) => fetchData('admin_per_tag_daily', e)"
          @sort="(e) => fetchData('admin_per_tag_daily', e)"
          :loading="tableLoading.admin_per_tag_daily"
        >
          <Column
            v-for="col in columnsPerTagDaily"
            :key="col.field"
            :field="col.field"
            :header="col.header"
            sortable
          >
          </Column>
          <template #loadingicon>
            <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
          </template>
          <template #paginatorstart>
            <Button
              type="button"
              icon="pi pi-refresh"
              @click="fetchData('admin_per_tag_daily', { page: 0, rows })"
              text
            />
          </template>
          <template #paginatorend>
            <download-csv :data="csvData.adminPerTagDaily" class="download-csv">
              <Button type="button" icon="pi pi-download" text />
            </download-csv>
          </template>
        </DataTable>
      </template>
    </Card>
    <Card class="mb-4">
      <template #title>
        <div class="text-xl">Per Tag Partner Daily</div>
      </template>
      <template #content>
        <DataTable
          :value="perTagpartnerDaily"
          :defaultSortOrder="-1"
          class="p-datatable-striped p-datatable-sm"
          :paginator="true"
          :rows="rows"
          :totalRecords="totalRecords.tagpartnerDaily"
          lazy
          @page="(e) => fetchData('admin_per_tagpartner_daily', e)"
          @sort="(e) => fetchData('admin_per_tagpartner_daily', e)"
          :loading="tableLoading.admin_per_tagpartner_daily"
        >
          <Column
            v-for="col in columnsPerTagpartnerDaily"
            :key="col.field"
            :field="col.field"
            :header="col.header"
            sortable
          >
          </Column>
          <template #loadingicon>
            <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
          </template>
          <template #paginatorstart>
            <Button
              type="button"
              icon="pi pi-refresh"
              @click="
                fetchData('admin_per_tagpartner_daily', { page: 0, rows })
              "
              text
            />
          </template>
          <template #paginatorend>
            <download-csv
              :data="csvData.adminPerTagpartnerDaily"
              class="download-csv"
            >
              <Button type="button" icon="pi pi-download" text />
            </download-csv>
          </template>
        </DataTable>
      </template>
    </Card>
    <Card class="mb-4">
      <template #title>
        <div class="text-xl">Per Tag Partner Monthly</div>
      </template>
      <template #content>
        <DataTable
          :value="perTagpartnerMonthly"
          :defaultSortOrder="-1"
          class="p-datatable-striped p-datatable-sm"
          :paginator="true"
          :rows="rows"
          :totalRecords="totalRecords.tagpartnerMonthly"
          lazy
          @page="(e) => fetchData('admin_per_tagpartner_monthly', e)"
          @sort="(e) => fetchData('admin_per_tagpartner_monthly', e)"
          :loading="tableLoading.admin_per_tagpartner_monthly"
        >
          <Column
            v-for="col in columnsPerTagpartnerDaily.filter(
              (item) => item.field !== 'geo'
            )"
            :key="col.field"
            :field="col.field"
            :header="col.header"
            sortable
          >
          </Column>
          <template #loadingicon>
            <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
          </template>
          <template #paginatorstart>
            <Button
              type="button"
              icon="pi pi-refresh"
              @click="
                fetchData('admin_per_tagpartner_monthly', { page: 0, rows })
              "
              text
            />
          </template>
          <template #paginatorend>
            <download-csv
              :data="csvData.adminPerTagpartnerMonthly"
              class="download-csv"
            >
              <Button type="button" icon="pi pi-download" text />
            </download-csv>
          </template>
        </DataTable>
      </template>
    </Card>
    <Card class="mb-4">
      <template #title>
        <div class="text-xl">Per Seller Daily</div>
      </template>
      <template #content>
        <DataTable
          :value="perSellerDaily"
          :defaultSortOrder="-1"
          class="p-datatable-striped p-datatable-sm"
          :paginator="true"
          :rows="rows"
          :totalRecords="totalRecords.sellerDaily"
          lazy
          @page="(e) => fetchData('admin_per_seller_daily', e)"
          @sort="(e) => fetchData('admin_per_seller_daily', e)"
          :loading="tableLoading.admin_per_seller_daily"
        >
          <Column
            v-for="col in columnsPerSellerDaily"
            :key="col.field"
            :field="col.field"
            :header="col.header"
            sortable
          >
          </Column>
          <template #loadingicon>
            <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
          </template>
          <template #paginatorstart>
            <Button
              type="button"
              icon="pi pi-refresh"
              @click="fetchData('admin_per_seller_daily', { page: 0, rows })"
              text
            />
          </template>
          <template #paginatorend>
            <download-csv
              :data="csvData.adminPerSellerDaily"
              class="download-csv"
            >
              <Button type="button" icon="pi pi-download" text />
            </download-csv>
          </template>
        </DataTable>
      </template>
    </Card>
  </div>
</template>

<script>
import { ref, onMounted, computed, watch } from "vue";
import axios from "axios";
import { API_BASE_URL } from "../consts";
import Calendar from "primevue/calendar";
import Card from "primevue/card";
import Button from "primevue/button";
import MultiSelect from "primevue/multiselect";
import { useStore } from "vuex";

import { formatCurrencyNumber, adjustDate } from "../helpers";
import LoaderSm from "./LoaderSm.vue";

export default {
  components: {
    Calendar,
    Card,
    Button,
    MultiSelect,
    LoaderSm,
  },
  setup() {
    const store = useStore();
    const columns = [
      { header: "Date", field: "date_trunc" },
      { header: "Player Loads", field: "loads" },
      { header: "Ad Impressions", field: "impressions" },
      { header: "Revenue", field: "revenue" },
      { header: "Cost", field: "cost" },
      { header: "Profit", field: "profit" },
      { header: "PM", field: "profit_margin" },
      { header: "RPM", field: "rpm" },
      { header: "CPM", field: "cpm" },
      { header: "RPL", field: "rpl" },
      { header: "CPL", field: "cpl" },
      { header: "Ad Play Rate", field: "fill_rate" },
    ];

    const columnsPerPartner = [
      { header: "Date", field: "date_trunc" },
      { header: "Partner", field: "partner_name" },
      { header: "Player Loads", field: "loads" },
      { header: "Ad Impressions", field: "impressions" },
      { header: "Revenue", field: "revenue" },
      { header: "Cost", field: "cost" },
      { header: "Profit", field: "profit" },
      { header: "PM", field: "profit_margin" },
      { header: "RPM", field: "rpm" },
      { header: "CPM", field: "cpm" },
      { header: "RPL", field: "rpl" },
      { header: "CPL", field: "cpl" },
      { header: "Ad Play Rate", field: "fill_rate" },
    ];

    const columnsPerGeo = [
      { header: "Date", field: "date_trunc" },
      { header: "Geo", field: "geo" },
      { header: "Player Loads", field: "loads" },
      { header: "Ad Impressions", field: "impressions" },
      { header: "Revenue", field: "revenue" },
      { header: "Cost", field: "cost" },
      { header: "Profit", field: "profit" },
      { header: "PM", field: "profit_margin" },
      { header: "RPM", field: "rpm" },
      { header: "CPM", field: "cpm" },
      { header: "RPL", field: "rpl" },
      { header: "CPL", field: "cpl" },
      { header: "Ad Play Rate", field: "fill_rate" },
    ];
    const columnsPerDomainDaily = [
      { header: "Date", field: "date_trunc" },
      { header: "Domain", field: "url" },
      { header: "Player Loads", field: "loads" },
      { header: "Ad Impressions", field: "impressions" },
      { header: "Revenue", field: "revenue" },
      { header: "Cost", field: "cost" },
      { header: "Profit", field: "profit" },
      { header: "PM", field: "profit_margin" },
      { header: "RPM", field: "rpm" },
      { header: "CPM", field: "cpm" },
      { header: "RPL", field: "rpl" },
      { header: "CPL", field: "cpl" },
      { header: "Ad Play Rate", field: "fill_rate" },
    ];

    const columnsPerGroupMonthly = [
      { header: "Date", field: "date_trunc" },
      { header: "Group", field: "group_name" },
      { header: "Player Loads", field: "loads" },
      { header: "Ad Impressions", field: "impressions" },
      { header: "Revenue", field: "revenue" },
      { header: "Cost", field: "cost" },
      { header: "Profit", field: "profit" },
      { header: "PM", field: "profit_margin" },
      { header: "RPM", field: "rpm" },
      { header: "CPM", field: "cpm" },
      { header: "RPL", field: "rpl" },
      { header: "CPL", field: "cpl" },
      { header: "Ad Play Rate", field: "fill_rate" },
    ];

    const columnsPerTagDaily = [
      { header: "Date", field: "date_trunc" },
      { header: "Tag ID", field: "tag_id" },
      { header: "Tag Name", field: "tag_name" },
      { header: "Partner", field: "tag_partner" },
      //{ header: "Geo", field: "geo" },
      { header: "Ad Impressions", field: "impressions" },
      { header: "Revenue", field: "revenue" },
      { header: "Requests", field: "requests" },
      { header: "Ad Play Rate", field: "fill_rate" },
    ];

    const columnsPerTagpartnerDaily = [
      { header: "Date", field: "date_trunc" },
      { header: "Partner", field: "tag_partner" },
      { header: "Geo", field: "geo" },
      { header: "Ad Impressions", field: "impressions" },
      { header: "Revenue", field: "revenue" },
      { header: "Requests", field: "requests" },
      { header: "Ad Play Rate", field: "fill_rate" },
    ];

    const columnsPerSellerDaily = [
      { header: "Date", field: "date_trunc" },
      { header: "Seller ID", field: "seller_id" },
      { header: "Seller", field: "seller_name" },
      { header: "Player Loads", field: "loads" },
      { header: "Ad Impressions", field: "impressions" },
      { header: "Revenue", field: "revenue" },
      { header: "Cost", field: "cost" },
      { header: "Profit", field: "profit" },
      { header: "RPM", field: "rpm" },
      { header: "CPM", field: "cpm" },
      { header: "RPL", field: "rpl" },
      { header: "CPL", field: "cpl" },
      { header: "Ad Play Rate", field: "fill_rate" },
    ];

    const rows = ref(10);
    const totalRecords = ref({
      daily: 0,
      monthly: 0,
      partnerly: 0,
      partnerMonthly: 0,
      geoly: 0,
      domainDaily: 0,
      groupMonthly: 0,
      tagDaily: 0,
      tagpartnerDaily: 0,
      tagpartnerMonthly: 0,
      domainMonthly: 0,
      geoMonthly: 0,
      sellerDaily: 0,
    });

    const monthlyData = ref([]);
    const dailyData = ref([]);
    const perPartnerDaily = ref([]);
    const perPartnerMonthly = ref([]);
    const perDomainDaily = ref([]);
    const perGroupMonthly = ref([]);
    const perGeoData = ref([]);
    const perTagDaily = ref([]);
    const perTagpartnerDaily = ref([]);
    const perTagpartnerMonthly = ref([]);
    const perDomainMonthly = ref([]);
    const perGeoMonthly = ref([]);
    const perSellerDaily = ref([]);

    //    const chartData = ref({});
    const chartDataPerPartner = ref({});
    const chartDataPerGeo = ref({});
    const csvData = ref({
      adminDaily: [],
      adminMonthly: [],
      adminPerGeoDaily: [],
      adminPerPartnerDaily: [],
      adminPerPartnerMonthly: [],
      adminPerDomainDaily: [],
      adminPerGroupMonthly: [],
      adminPerTagDaily: [],
      adminPerTagpartnerDaily: [],
      adminPerTagpartnerMonthly: [],
      adminPerDomainMonthly: [],
      adminPerGeoMonthly: [],
      adminPerSellerDaily: [],
    });

    const memberList = ref({
      partners: [],
      groups: [],
      sellers: [],
    });

    const tagGroups = ref([]);
    const tags = ref([]);

    const memberFilter = ref({
      partners: [],
      groups: [],
      sellers: [],
      tags: [],
      tag_partners: [],
    });

    const tableLoading = ref({
      admin_daily: false,
      admin_monthly: false,
      admin_per_partner_daily: false,
      admin_per_partner_monthly: false,
      admin_per_domain_daily: false,
      admin_per_group_monthly: false,
      admin_per_geo_daily: false,
      admin_per_tag_daily: false,
      admin_per_tagpartner_daily: false,
      admin_per_tagpartner_monthly: false,
      admin_per_domain_monthly: false,
      admin_per_geo_monthly: false,
      admin_per_seller_daily: false,
    });

    const chartLoading = ref({
      admin_daily: false,
      admin_per_partner_daily: false,
      admin_per_geo_daily: false,
    });

    const visibleDatasets = ref([0, 1, 2, 3]); // Initially show all datasets

    const chartRef = ref(null);
    const chartData = ref([
      { name: "Loads", data: {} },
      { name: "Impressions", data: {} },
      { name: "Revenue", data: {} },
      { name: "Profit", data: {} },
    ]);

    const chartOptions = computed(() => ({
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        y: {
          beginAtZero: true,
        },
      },
      plugins: {
        legend: {
          display: false, // Hide default legend as we're using checkboxes
        },
      },
    }));

    const filteredChartData = computed(() =>
      chartData.value.filter((dataset, index) =>
        visibleDatasets.value.includes(index)
      )
    );

    watch(visibleDatasets, () => {
      // Trigger chart update when visibleDatasets changes
      if (chartRef.value && chartRef.value.chart) {
        chartRef.value.chart.update();
      }
    });

    // Get today's date
    var today = ref(new Date());
    today.value.setHours(0, 0, 0, 0); // Set time to 00:00:00

    // Get the date 30 days ago
    var thirtyDaysAgo = ref(new Date());
    thirtyDaysAgo.value.setDate(thirtyDaysAgo.value.getDate() - 7);
    thirtyDaysAgo.value.setHours(0, 0, 0, 0); // Set time to 00:00:00

    const dateRange = ref([thirtyDaysAgo.value, today.value]);

    const setDateRange = (value) => {
      dateRange.value = value;
    };

    const finishLoading = (dataMode) => {
      if (dataMode.includes("all"))
        chartLoading.value[dataMode.slice(0, -4)] = false;
      else tableLoading.value[dataMode] = false;
    };

    const fetchData = async (
      dataMode,
      { page = 0, rows = 5, sortField, sortOrder }
    ) => {
      try {
        const token = localStorage.getItem("adminToken");
        if (dataMode.includes("all"))
          chartLoading.value[dataMode.slice(0, -4)] = true;
        else tableLoading.value[dataMode] = true;

        const startDate = adjustDate(dateRange.value?.[0]);
        const endDate = adjustDate(dateRange.value?.[1]);

        const { data } = await axios.get(API_BASE_URL + "/admin/dashboard", {
          params: {
            startDate: startDate.toISOString().split("T")[0],
            endDate: endDate.toISOString().split("T")[0],
            dataMode,
            page,
            rows,
            sortField,
            sortOrder,
            memberFilter: JSON.stringify({
              ...memberFilter.value,
              tags: dataMode.includes("admin_per_tag_daily")
                ? memberFilter.value.tags
                : [],
              tag_partners: dataMode.includes("admin_per_tagpartner")
                ? memberFilter.value.tag_partners
                : [],
            }),
          },

          headers: { Authorization: `Bearer ${token}` },
        });

        if (data.success) {
          if (data.data?.adminMonthly?.length) {
            if (dataMode == "admin_monthly") {
              totalRecords.value.monthly = parseInt(
                data.data.adminMonthly[0].count
              );
              monthlyData.value = data.data.adminMonthly.map((item) => {
                return {
                  date_trunc: item.date_trunc,
                  loads: item.loads.toLocaleString(),
                  impressions: item.impressions.toLocaleString(),
                  revenue: formatCurrencyNumber(item.revenue),
                  cost: formatCurrencyNumber(item.cost),
                  profit: formatCurrencyNumber(item.profit),
                  profit_margin: `${(item.profit_margin * 100).toFixed(0)}%`,
                  cpm: formatCurrencyNumber(item.cpm),
                  rpm: formatCurrencyNumber(item.rpm),
                  cpl: formatCurrencyNumber(item.cpl),
                  rpl: formatCurrencyNumber(item.rpl),
                  fill_rate: `${(item.fill_rate * 100).toFixed(0)}%`,
                };
              });
            } else {
              csvData.value.adminMonthly = data.data.adminMonthly;
            }
          } else if (dataMode == "admin_monthly") {
            totalRecords.value.monthly = 0;
            monthlyData.value = [];
          }
          if (data.data?.adminDaily?.length) {
            if (dataMode == "admin_daily") {
              totalRecords.value.daily = parseInt(
                data.data.adminDaily[0].count
              );

              dailyData.value = data.data.adminDaily.map((item) => {
                return {
                  date_trunc: item.date_trunc.split("T")[0],
                  loads: item.loads.toLocaleString(),
                  impressions: item.impressions.toLocaleString(),
                  revenue: formatCurrencyNumber(item.revenue),
                  cost: formatCurrencyNumber(item.cost),
                  profit: formatCurrencyNumber(item.profit),
                  profit_margin: `${(item.profit_margin * 100).toFixed(0)}%`,
                  cpm: formatCurrencyNumber(item.cpm),
                  rpm: formatCurrencyNumber(item.rpm),
                  cpl: formatCurrencyNumber(item.cpl),
                  rpl: formatCurrencyNumber(item.rpl),
                  fill_rate: `${(item.fill_rate * 100).toFixed(0)}%`,
                };
              });
            } else {
              csvData.value.adminDaily = data.data.adminDaily;

              const loadsData = {};
              const impressionsData = {};
              const revenueData = {};
              const profitData = {};

              data.data.adminDaily.forEach((item) => {
                const date = item.date_trunc.split("T")[0];
                loadsData[date] = item.loads;
                impressionsData[date] = item.impressions;
                revenueData[date] = parseFloat(item.revenue.toFixed(2));
                profitData[date] = parseFloat(item.profit.toFixed(2));
              });

              chartData.value = [
                { name: "Loads", data: loadsData },
                { name: "Impressions", data: impressionsData },
                { name: "Revenue", data: revenueData },
                { name: "Profit", data: profitData },
              ];
            }
          } else if (dataMode == "admin_daily") {
            totalRecords.value.daily = 0;
            dailyData.value = [];
            chartData.value = [];
          }

          if (data.data?.adminPerPartnerDaily?.length) {
            if (dataMode == "admin_per_partner_daily") {
              totalRecords.value.partnerly = parseInt(
                data.data.adminPerPartnerDaily[0].count
              );
              perPartnerDaily.value = data.data.adminPerPartnerDaily;

              perPartnerDaily.value = perPartnerDaily.value.map((item) => {
                return {
                  date_trunc: item.date_trunc.split("T")[0],
                  partner_name: item.partner_name,
                  loads: item.loads.toLocaleString(),
                  impressions: item.impressions.toLocaleString(),
                  revenue: formatCurrencyNumber(item.revenue),
                  cost: formatCurrencyNumber(item.cost),
                  profit: formatCurrencyNumber(item.profit),
                  profit_margin: `${(item.profit_margin * 100).toFixed(0)}%`,
                  cpm: formatCurrencyNumber(item.cpm),
                  rpm: formatCurrencyNumber(item.rpm),
                  cpl: formatCurrencyNumber(item.cpl),
                  rpl: formatCurrencyNumber(item.rpl),
                  fill_rate: `${(item.fill_rate * 100).toFixed(0)}%`,
                };
              });
            } else {
              csvData.value.adminPerPartnerDaily =
                data.data.adminPerPartnerDaily;
              const perPartnerChartData = {};

              let perPartnerDailyChart = data.data.adminPerPartnerDaily;

              perPartnerDailyChart = perPartnerDailyChart.map((item) => {
                return {
                  date_trunc: item.date_trunc.split("T")[0],
                  partner_name: item.partner_name,
                  loads: item.loads.toLocaleString(),
                  impressions: item.impressions.toLocaleString(),
                  revenue: formatCurrencyNumber(item.revenue),
                  cost: formatCurrencyNumber(item.cost),
                  profit: formatCurrencyNumber(item.profit),
                  profit_margin: `${(item.profit_margin * 100).toFixed(0)}%`,
                  cpm: formatCurrencyNumber(item.cpm),
                  rpm: formatCurrencyNumber(item.rpm),
                  cpl: formatCurrencyNumber(item.cpl),
                  rpl: formatCurrencyNumber(item.rpl),
                  fill_rate: `${(item.fill_rate * 100).toFixed(0)}%`,
                };
              });

              for (let i = 0; i < perPartnerDailyChart.length; i++) {
                const domainData = perPartnerDailyChart[i];

                if (!perPartnerChartData[domainData.partner_name]) {
                  perPartnerChartData[domainData.partner_name] = {};
                }

                perPartnerChartData[domainData.partner_name][
                  domainData.date_trunc.split("T")[0]
                ] = domainData.revenue;
              }

              chartDataPerPartner.value = Object.entries(
                perPartnerChartData
              ).map(([partner_name, data]) => ({
                name: partner_name,
                data: Object.entries(data).reduce((acc, [date, price]) => {
                  acc[date] = parseFloat(price.replace(/[$,]/g, ""));
                  return acc;
                }, {}),
              }));
            }
          } else if (dataMode == "admin_per_partner_daily") {
            totalRecords.value.partnerly = 0;
            perPartnerDaily.value = [];
            chartDataPerPartner.value = [];
          }
          if (data.data?.adminPerPartnerMonthly?.length) {
            if (dataMode == "admin_per_partner_monthly") {
              totalRecords.value.partnerMonthly = parseInt(
                data.data.adminPerPartnerMonthly[0].count
              );
              perPartnerMonthly.value = data.data.adminPerPartnerMonthly.map(
                (item) => {
                  return {
                    date_trunc: item.date_trunc,
                    partner_name: item.partner_name,
                    loads: item.loads.toLocaleString(),
                    impressions: item.impressions.toLocaleString(),
                    revenue: formatCurrencyNumber(item.revenue),
                    cost: formatCurrencyNumber(item.cost),
                    profit: formatCurrencyNumber(item.profit),
                    profit_margin: `${(item.profit_margin * 100).toFixed(0)}%`,
                    cpm: formatCurrencyNumber(item.cpm),
                    rpm: formatCurrencyNumber(item.rpm),
                    cpl: formatCurrencyNumber(item.cpl),
                    rpl: formatCurrencyNumber(item.rpl),
                    fill_rate: `${(item.fill_rate * 100).toFixed(0)}%`,
                  };
                }
              );
            } else {
              csvData.value.adminPerPartnerMonthly =
                data.data.adminPerPartnerMonthly;
            }
          } else if (dataMode == "admin_per_partner_monthly") {
            totalRecords.value.partnerMonthly = 0;
            perPartnerMonthly.value = [];
          }
          if (data.data?.adminPerGeoDaily?.length) {
            if (dataMode == "admin_per_geo_daily") {
              totalRecords.value.geoly = parseInt(
                data.data.adminPerGeoDaily[0].count
              );
              perGeoData.value = data.data.adminPerGeoDaily;

              perGeoData.value = perGeoData.value.map((item) => {
                return {
                  date_trunc: item.date_trunc.split("T")[0],
                  geo: item.geo,
                  loads: item.loads.toLocaleString(),
                  impressions: item.impressions.toLocaleString(),
                  revenue: formatCurrencyNumber(item.revenue),
                  cost: formatCurrencyNumber(item.cost),
                  profit: formatCurrencyNumber(item.profit),
                  profit_margin: `${(item.profit_margin * 100).toFixed(0)}%`,
                  cpm: formatCurrencyNumber(item.cpm),
                  rpm: formatCurrencyNumber(item.rpm),
                  cpl: formatCurrencyNumber(item.cpl),
                  rpl: formatCurrencyNumber(item.rpl),
                  fill_rate: `${(item.fill_rate * 100).toFixed(0)}%`,
                };
              });
            } else {
              csvData.value.adminPerGeoDaily = data.data.adminPerGeoDaily;
              const perGeoChartData = {};

              let perGeoDataChart = data.data.adminPerGeoDaily;

              perGeoDataChart = perGeoDataChart.map((item) => {
                return {
                  date_trunc: item.date_trunc.split("T")[0],
                  geo: item.geo,
                  loads: item.loads.toLocaleString(),
                  impressions: item.impressions.toLocaleString(),
                  revenue: formatCurrencyNumber(item.revenue),
                  cost: formatCurrencyNumber(item.cost),
                  profit: formatCurrencyNumber(item.profit),
                  profit_margin: `${(item.profit_margin * 100).toFixed(0)}%`,
                  cpm: formatCurrencyNumber(item.cpm),
                  rpm: formatCurrencyNumber(item.rpm),
                  cpl: formatCurrencyNumber(item.cpl),
                  rpl: formatCurrencyNumber(item.rpl),
                  fill_rate: `${(item.fill_rate * 100).toFixed(0)}%`,
                };
              });

              for (let i = 0; i < perGeoDataChart.length; i++) {
                const geoData = perGeoDataChart[i];

                if (!perGeoChartData[geoData.geo]) {
                  perGeoChartData[geoData.geo] = {};
                }

                perGeoChartData[geoData.geo][geoData.date_trunc.split("T")[0]] =
                  geoData.revenue;
              }

              chartDataPerGeo.value = Object.entries(perGeoChartData).map(
                ([geo, data]) => ({
                  name: geo,
                  data: Object.entries(data).reduce((acc, [date, price]) => {
                    acc[date] = parseFloat(price.replace(/[$,]/g, ""));
                    return acc;
                  }, {}),
                })
              );
            }
          } else if (dataMode == "admin_per_geo_daily") {
            totalRecords.value.geoly = 0;
            perGeoData.value = [];
            chartDataPerGeo.value = [];
          }
          if (data.data?.adminPerDomainDaily?.length) {
            if (dataMode == "admin_per_domain_daily") {
              totalRecords.value.domainDaily = parseInt(
                data.data.adminPerDomainDaily[0].count
              );
              perDomainDaily.value = data.data.adminPerDomainDaily;

              perDomainDaily.value = perDomainDaily.value.map((item) => {
                return {
                  date_trunc: item.date_trunc.split("T")[0],
                  url: item.url,
                  loads: item.loads.toLocaleString(),
                  impressions: item.impressions.toLocaleString(),
                  revenue: formatCurrencyNumber(item.revenue),
                  cost: formatCurrencyNumber(item.cost),
                  profit: formatCurrencyNumber(item.profit),
                  profit_margin: `${(item.profit_margin * 100).toFixed(0)}%`,
                  cpm: formatCurrencyNumber(item.cpm),
                  rpm: formatCurrencyNumber(item.rpm),
                  cpl: formatCurrencyNumber(item.cpl),
                  rpl: formatCurrencyNumber(item.rpl),
                  fill_rate: `${(item.fill_rate * 100).toFixed(0)}%`,
                };
              });
            } else {
              csvData.value.adminPerDomainDaily = data.data.adminPerDomainDaily;
            }
          } else if (dataMode == "admin_per_domain_daily") {
            totalRecords.value.domainDaily = 0;
            perDomainDaily.value = [];
          }
          if (data.data?.adminPerGroupMonthly?.length) {
            if (dataMode == "admin_per_group_monthly") {
              totalRecords.value.groupMonthly = parseInt(
                data.data.adminPerGroupMonthly[0].count
              );
              perGroupMonthly.value = data.data.adminPerGroupMonthly;

              perGroupMonthly.value = perGroupMonthly.value.map((item) => {
                return {
                  date_trunc: item.date_trunc.split("T")[0],
                  group_name: item.group_name,
                  loads: item.loads.toLocaleString(),
                  impressions: item.impressions.toLocaleString(),
                  revenue: formatCurrencyNumber(item.revenue),
                  cost: formatCurrencyNumber(item.cost),
                  profit: formatCurrencyNumber(item.profit),
                  profit_margin: `${(item.profit_margin * 100).toFixed(0)}%`,
                  cpm: formatCurrencyNumber(item.cpm),
                  rpm: formatCurrencyNumber(item.rpm),
                  cpl: formatCurrencyNumber(item.cpl),
                  rpl: formatCurrencyNumber(item.rpl),
                  fill_rate: `${(item.fill_rate * 100).toFixed(0)}%`,
                };
              });
            } else {
              csvData.value.adminPerGroupMonthly =
                data.data.adminPerGroupMonthly;
            }
          } else if (dataMode == "admin_per_group_monthly") {
            totalRecords.value.groupMonthly = 0;
            perGroupMonthly.value = [];
          }
          if (data.data?.adminPerTagDaily?.length) {
            if (dataMode == "admin_per_tag_daily") {
              totalRecords.value.tagDaily = parseInt(
                data.data.adminPerTagDaily[0].count
              );
              perTagDaily.value = data.data.adminPerTagDaily.map((item) => {
                return {
                  date_trunc: item.date_trunc,
                  tag_id: item.tag_id,
                  tag_name: item.tag_name,
                  tag_partner: item.tag_partner,
                  geo: item.geo,
                  impressions: item.impressions.toLocaleString(),
                  revenue: formatCurrencyNumber(item.revenue),
                  requests: item.requests.toLocaleString(),
                  fill_rate: `${
                    item.requests == 0
                      ? 0
                      : ((item.impressions / item.requests) * 100).toFixed(2)
                  }%`,
                };
              });
            } else {
              csvData.value.adminPerTagDaily = data.data.adminPerTagDaily;
            }
          } else if (dataMode == "admin_per_tag_daily") {
            totalRecords.value.tagDaily = 0;
            perTagDaily.value = [];
          }
          if (data.data?.adminPerTagpartnerDaily?.length) {
            if (dataMode == "admin_per_tagpartner_daily") {
              totalRecords.value.tagpartnerDaily = parseInt(
                data.data.adminPerTagpartnerDaily[0].count
              );
              perTagpartnerDaily.value = data.data.adminPerTagpartnerDaily.map(
                (item) => {
                  return {
                    date_trunc: item.date_trunc,
                    tag_partner: item.tag_partner,
                    geo: item.geo,
                    impressions: item.impressions.toLocaleString(),
                    revenue: formatCurrencyNumber(item.revenue),
                    requests: item.requests.toLocaleString(),
                    fill_rate: `${
                      item.requests == 0
                        ? 0
                        : ((item.impressions / item.requests) * 100).toFixed(2)
                    }%`,
                  };
                }
              );
            } else {
              csvData.value.adminPerTagpartnerDaily =
                data.data.adminPerTagpartnerDaily;
            }
          } else if (dataMode == "admin_per_tagpartner_daily") {
            totalRecords.value.tagpartnerDaily = 0;
            perTagpartnerDaily.value = [];
          }
          if (data.data?.adminPerTagpartnerMonthly?.length) {
            if (dataMode == "admin_per_tagpartner_monthly") {
              totalRecords.value.tagpartnerMonthly = parseInt(
                data.data.adminPerTagpartnerMonthly[0].count
              );
              perTagpartnerMonthly.value =
                data.data.adminPerTagpartnerMonthly.map((item) => {
                  return {
                    date_trunc: item.date_trunc.split("T")[0],
                    tag_partner: item.tag_partner,
                    impressions: item.impressions.toLocaleString(),
                    revenue: formatCurrencyNumber(item.revenue),
                    requests: item.requests.toLocaleString(),
                    fill_rate: `${
                      item.requests == 0
                        ? 0
                        : ((item.impressions / item.requests) * 100).toFixed(2)
                    }%`,
                  };
                });
            } else {
              csvData.value.adminPerTagpartnerMonthly =
                data.data.adminPerTagpartnerMonthly;
            }
          } else if (dataMode == "admin_per_tagpartner_monthly") {
            totalRecords.value.tagpartnerMonthly = 0;
            perTagpartnerMonthly.value = [];
          }
          if (data.data?.adminPerDomainMonthly?.length) {
            if (dataMode == "admin_per_domain_monthly") {
              totalRecords.value.domainMonthly = parseInt(
                data.data.adminPerDomainMonthly[0].count
              );
              perDomainMonthly.value = data.data.adminPerDomainMonthly.map(
                (item) => {
                  return {
                    date_trunc: item.date_trunc.split("T")[0],
                    url: item.url,
                    loads: item.loads.toLocaleString(),
                    impressions: item.impressions.toLocaleString(),
                    revenue: formatCurrencyNumber(item.revenue),
                    cost: formatCurrencyNumber(item.cost),
                    profit: formatCurrencyNumber(item.profit),
                    cpm: formatCurrencyNumber(item.cpm),
                    rpm: formatCurrencyNumber(item.rpm),
                    cpl: formatCurrencyNumber(item.cpl),
                    rpl: formatCurrencyNumber(item.rpl),
                    fill_rate: `${(item.fill_rate * 100).toFixed(0)}%`,
                  };
                }
              );
            } else {
              csvData.value.adminPerDomainMonthly =
                data.data.adminPerDomainMonthly;
            }
          } else if (dataMode == "admin_per_domain_monthly") {
            totalRecords.value.domainMonthly = 0;
            perDomainMonthly.value = [];
          }
          if (data.data?.adminPerGeoMonthly?.length) {
            if (dataMode == "admin_per_geo_monthly") {
              totalRecords.value.geoMonthly = parseInt(
                data.data.adminPerGeoMonthly[0].count
              );
              perGeoMonthly.value = data.data.adminPerGeoMonthly.map((item) => {
                return {
                  date_trunc: item.date_trunc.split("T")[0],
                  geo: item.geo,
                  loads: item.loads.toLocaleString(),
                  impressions: item.impressions.toLocaleString(),
                  revenue: formatCurrencyNumber(item.revenue),
                  cost: formatCurrencyNumber(item.cost),
                  profit: formatCurrencyNumber(item.profit),
                  profit_margin: `${(item.profit_margin * 100).toFixed(0)}%`,
                  cpm: formatCurrencyNumber(item.cpm),
                  rpm: formatCurrencyNumber(item.rpm),
                  cpl: formatCurrencyNumber(item.cpl),
                  rpl: formatCurrencyNumber(item.rpl),
                  fill_rate: `${(item.fill_rate * 100).toFixed(0)}%`,
                };
              });
            } else {
              csvData.value.adminPerGeoMonthly = data.data.adminPerGeoMonthly;
            }
          } else if (dataMode == "admin_per_geo_monthly") {
            totalRecords.value.geoMonthly = 0;
            perGeoMonthly.value = [];
          }
          if (data.data?.adminPerSellerDaily?.length) {
            if (dataMode == "admin_per_seller_daily") {
              totalRecords.value.sellerDaily = parseInt(
                data.data.adminPerSellerDaily[0].count
              );
              perSellerDaily.value = data.data.adminPerSellerDaily.map(
                (item) => {
                  return {
                    date_trunc: item.date_trunc.split("T")[0],
                    loads: item.loads.toLocaleString(),
                    impressions: item.impressions.toLocaleString(),
                    revenue: formatCurrencyNumber(item.revenue),
                    cost: formatCurrencyNumber(item.cost),
                    profit: formatCurrencyNumber(item.profit),
                    cpm: formatCurrencyNumber(item.cpm),
                    rpm: formatCurrencyNumber(item.rpm),
                    cpl: formatCurrencyNumber(item.cpl),
                    rpl: formatCurrencyNumber(item.rpl),
                    fill_rate: `${(item.fill_rate * 100).toFixed(0)}%`,
                    seller_id: item.seller_id,
                    seller_name: item.seller_name,
                  };
                }
              );
            } else {
              csvData.value.adminPerSellerDaily = data.data.adminPerSellerDaily;
            }
          } else if (dataMode == "admin_per_seller_daily") {
            totalRecords.value.sellerDaily = 0;
            perSellerDaily.value = [];
          }
        }
      } catch (error) {
        // Handle errors
        console.log(error);
      } finally {
        finishLoading(dataMode);
      }
    };

    const fetchTagGroups = async () => {
      try {
        const token = localStorage.getItem("adminToken");
        const response = await axios.get(API_BASE_URL + "/tag-group", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.data) {
          tagGroups.value = response.data;
        }
      } catch (error) {
        console.error("Error fetching tag groups:", error);
      }
    };

    const fetchTags = async () => {
      try {
        const token = localStorage.getItem("adminToken");
        const response = await axios.get(`${API_BASE_URL}/tags`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.data?.tags) {
          tags.value = response.data.tags;
        }
      } catch (error) {
        console.error("Error fetching tags:", error);
      }
    };

    const fetchOnDateRange = async () => {
      const dataModeArr = [
        "admin_daily",
        "admin_daily_all",
        "admin_monthly",
        "admin_monthly_all",
        "admin_per_partner_daily",
        "admin_per_partner_daily_all",
        "admin_per_partner_monthly",
        "admin_per_partner_monthly_all",
        "admin_per_geo_daily",
        "admin_per_geo_daily_all",
        "admin_per_domain_daily",
        "admin_per_domain_daily_all",
        "admin_per_group_monthly",
        "admin_per_group_monthly_all",
        "admin_per_domain_monthly",
        "admin_per_domain_monthly_all",
        "admin_per_geo_monthly",
        "admin_per_geo_monthly_all",
        "admin_per_tag_daily",
        "admin_per_tag_daily_all",
        "admin_per_tagpartner_daily",
        "admin_per_tagpartner_daily_all",
        "admin_per_tagpartner_monthly",
        "admin_per_tagpartner_monthly_all",
        "admin_per_seller_daily",
        "admin_per_seller_daily_all",
      ];
      dataModeArr.map((dataMode) =>
        fetchData(dataMode, {
          page: 0,
          rows: rows.value,
          sortField: null,
          sortOrder: null,
        })
      );
    };

    const fetchMemberList = async () => {
      try {
        const token = localStorage.getItem("adminToken");
        const { data } = await axios.get(API_BASE_URL + "/member-list/all", {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (data.success) {
          const { partners, groups, sellers } = data;
          memberList.value = { partners, groups, sellers };
        }
      } catch (error) {
        console.log(error);
      }
    };

    onMounted(() => {
      fetchMemberList();
      fetchTags();
      fetchTagGroups();
      fetchOnDateRange();
    });

    return {
      totalRecords,
      columns,
      columnsPerGeo,
      monthlyData,
      dailyData,
      fetchData,
      fetchOnDateRange,
      chartData,
      dateRange,
      setDateRange,
      csvData,
      rows,
      totalRecords,
      chartDataPerPartner,
      chartDataPerGeo,
      columnsPerPartner,
      columnsPerDomainDaily,
      columnsPerGroupMonthly,
      columnsPerTagDaily,
      columnsPerTagpartnerDaily,
      columnsPerSellerDaily,
      perPartnerDaily,
      perPartnerMonthly,
      perGeoData,
      perDomainDaily,
      perGroupMonthly,
      perDomainMonthly,
      perGeoMonthly,
      perTagDaily,
      perTagpartnerDaily,
      perTagpartnerMonthly,
      perSellerDaily,
      tableLoading,
      chartLoading,
      memberList,
      memberFilter,
      tags,
      tagGroups,
      visibleDatasets,
      chartOptions,
      filteredChartData,
      chartRef,
    };
  },
};
</script>
