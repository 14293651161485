<template>
  <footer class="footer w-full surface-0">
    <div class="container">
      <p style="color: var(--text-color)">JAMX 2024</p>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style scoped>
.footer {
  align-self: end;
  padding: 0.75rem 0;
  text-align: center;
}

p {
  margin: 0;
}
</style>
