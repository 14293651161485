<template>
  <div>
    <h2 class="mt-0" style="color: var(--text-color)">
      {{ isEditing ? "Edit Content Pack" : "Add New Content Pack" }}
    </h2>
    <Card>
      <template #header>
        <div
          class="p-3 pb-0 flex flex-column sm:flex-row justify-content-between gap-3"
        >
          <InputText
            class="w-full sm:w-20rem"
            v-model="contentPackName"
            type="text"
            placeholder="Content Pack Name"
          />
          <Button
            class="justify-content-center"
            @click="saveContentPack"
            :loading="loading"
          >
            {{ isEditing ? "Update" : "Create" }} Content Pack
          </Button>
        </div>
      </template>
      <template #content>
        <DataTable
          :value="shows"
          :paginator="true"
          :rows="10"
          :filters="showsTableFilter"
          class="p-datatable-sm"
          :striped-rows="true"
          selectionMode="multiple"
          v-model:selection="selectedShows"
          :loading="loading"
        >
          <Column field="show_title" header="Title" :sortable="true" />
          <Column field="rss_url" header="RSS link" :sortable="true" />
          <Column field="last_fetched" header="Last Fetched" :sortable="true">
            <template #body="slotProps">
              {{ slotProps.data.last_fetched || "Never" }}
            </template>
          </Column>
        </DataTable>
      </template>
    </Card>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import axios from "axios";
import { API_BASE_URL } from "../consts.js";
import { useToast } from "primevue/usetoast";
import Card from "primevue/card";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import DataTable from "primevue/datatable";
import Column from "primevue/column";

const toast = useToast();
const store = useStore();
const router = useRouter();
const route = useRoute();
const contentPackName = ref("");
const selectedShows = ref([]);
const loading = ref(false);
const showsTableFilter = ref({
  global: { value: null, matchMode: "contains" },
});

const token = localStorage.getItem("adminToken");
const packId = computed(() => route.params.id);
const isEditing = computed(() => !!packId.value);

const shows = computed(() => store.getters.shows);

const fetchContentPack = async () => {
  if (!isEditing.value) return;

  try {
    loading.value = true;
    const response = await axios.get(
      `${API_BASE_URL}/content-packs/${packId.value}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    contentPackName.value = response.data.name;
    selectedShows.value = shows.value.filter((show) =>
      response.data.shows.includes(show.show_id)
    );
  } catch (error) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: "Failed to fetch content pack details",
      life: 3000,
    });
    router.push("/admin/content-packs");
  } finally {
    loading.value = false;
  }
};

const saveContentPack = async () => {
  if (!contentPackName.value) {
    toast.add({
      severity: "warn",
      summary: "Warning",
      detail: "Please enter a content pack name",
      life: 3000,
    });
    return;
  }

  if (selectedShows.value.length === 0) {
    toast.add({
      severity: "warn",
      summary: "Warning",
      detail: "Please select at least one show",
      life: 3000,
    });
    return;
  }

  try {
    loading.value = true;
    const payload = {
      name: contentPackName.value,
      shows: selectedShows.value.map((show) => show.show_id),
    };

    const url = isEditing.value
      ? `${API_BASE_URL}/content-packs/${packId.value}`
      : `${API_BASE_URL}/content-packs`;

    const method = isEditing.value ? "put" : "post";

    await axios[method](url, payload, {
      headers: { Authorization: `Bearer ${token}` },
    });

    toast.add({
      severity: "success",
      summary: "Success",
      detail: `Content pack ${
        isEditing.value ? "updated" : "created"
      } successfully`,
      life: 3000,
    });

    router.push("/admin/content-packs");
  } catch (error) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: `Failed to ${isEditing.value ? "update" : "create"} content pack`,
      life: 3000,
    });
  } finally {
    loading.value = false;
  }
};

onMounted(async () => {
  if (!shows.value || shows.value.length === 0) {
    await store.dispatch("loadShows", { token });
  }
  if (isEditing.value) {
    await fetchContentPack();
  }
});
</script>
