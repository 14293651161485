<template>
  <div>
    <Card>
      <template #content>
        <DataTable
          :value="contentPacks"
          :paginator="true"
          :rows="10"
          class="p-datatable-sm"
          :striped-rows="true"
          v-model:selection="selectedPack"
        >
          <Column field="id" header="ID" :sortable="true"></Column>
          <Column field="name" header="Name" :sortable="true"></Column>
          <Column field="shows" header="Shows" :sortable="true">
            <template #body="slotProps">
              {{
                Array.isArray(slotProps.data.shows)
                  ? slotProps.data.shows.length
                  : 0
              }}
              shows
            </template>
          </Column>
          <Column header="Actions" :exportable="false" style="min-width: 8rem">
            <template #body="slotProps">
              <div class="flex gap-2">
                <Button
                  icon="pi pi-pencil"
                  outlined
                  severity="info"
                  @click="navigateToEdit(slotProps.data)"
                  aria-label="Edit"
                />
                <Button
                  icon="pi pi-trash"
                  outlined
                  severity="danger"
                  @click="confirmDelete(slotProps.data)"
                  aria-label="Delete"
                />
              </div>
            </template>
          </Column>
        </DataTable>
      </template>
    </Card>

    <ConfirmDialog></ConfirmDialog>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useConfirm } from "primevue/useconfirm";
import { useToast } from "primevue/usetoast";
import Card from "primevue/card";
import Button from "primevue/button";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import ConfirmDialog from "primevue/confirmdialog";
import axios from "axios";
import { API_BASE_URL } from "../consts";

const router = useRouter();
const confirm = useConfirm();
const toast = useToast();
const token = localStorage.getItem("adminToken");
const contentPacks = ref([]);
const selectedPack = ref(null);

const fetchContentPacks = async () => {
  try {
    const response = await axios.get(API_BASE_URL + "/content-packs", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log(response.data);

    /* response.data = response.data.map((item) => {
      console.log(item);
      return {
        ...item,
        shows: JSON.parse(item.shows),
      };
    });*/

    contentPacks.value = response.data;
  } catch (error) {
    console.log(error);
    toast.add({
      severity: "error",
      summary: "Error",
      detail: "Failed to fetch content packs",
      life: 3000,
    });
  }
};

const navigateToAdd = () => {
  router.push("/admin/content-packs/add");
};

const navigateToEdit = (pack) => {
  router.push(`/admin/content-packs/edit/${pack.id}`);
};

const confirmDelete = (pack) => {
  confirm.require({
    message: "Are you sure you want to delete this content pack?",
    header: "Delete Confirmation",
    icon: "pi pi-exclamation-triangle",
    accept: () => deleteContentPack(pack.id),
  });
};

const deleteContentPack = async (id) => {
  try {
    await axios.delete(`${API_BASE_URL}/content-packs/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    await fetchContentPacks();
    toast.add({
      severity: "success",
      summary: "Success",
      detail: "Content pack deleted successfully",
      life: 3000,
    });
  } catch (error) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: "Failed to delete content pack",
      life: 3000,
    });
  }
};

onMounted(() => {
  fetchContentPacks();
});
</script>
