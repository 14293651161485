<template>
  <v-card class="statistics-card elevation-1">
    <v-card-title class="flex justify-content-between align-center pa-4">
      <span class="text-h6 font-weight-medium statistics-card-type-title">{{
        type
      }}</span>
      <download-csv :data="props.data" class="download-csv">
        <Button type="button" icon="pi pi-download" text />
      </download-csv>
    </v-card-title>

    <v-card-text class="pa-0">
      <div class="statistics-card-content">
        <widget-loader v-if="loading" />
        <line-chart
          v-else
          :data="chartData"
          :discrete="true"
          :colors="['#4CAF50']"
          height="400px"
          :suffix="` ${type.toLowerCase()}`"
          :curve="true"
          :points="true"
          :legend="false"
          :labels="{ fontSize: 12 }"
        />
      </div>
    </v-card-text>
  </v-card>
</template>

<script setup>
import { computed } from "vue";
import moment from "moment";
import WidgetLoader from "./WidgetLoader.vue";
import Button from "primevue/button";

const props = defineProps(["loading", "type", "data"]);

let CSVData = {};

const chartData = computed(() => {
  let items = {};
  props.data.forEach((item) => {
    const date = moment(item.date).format("MMM DD");
    items[date] = props.type === "Views" ? item.views : item.plays;
  });
  CSVData = items;
  return items;
});
</script>

<style scoped>
.statistics-card {
  background: var(--overview-card-bg);
  border-radius: 8px;
}
.statistics-card-content {
  height: 400px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
}

.statistics-card-type-title {
  color: var(--text-color);
}
</style>
